<!-- eslint-disable no-undef -->
<script setup>
    // Imports
    import { ref, watch, computed } from 'vue';
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';

    // Deffinitions
    const emit = defineEmits(['RowsSelected']);

    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        pagination: {
            type: Boolean,
            default: false
        },
        rowsPerPage: {
            type: Number,
            default: 10
        },
        pageSizeOptions: {
            type: Array,
            default: () => [5, 10, 25, 50, 100]
        }
    });

    // References
    const columns = ref([]);
    const selectedRows = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(props.rowsPerPage);

    // Watch functions
    watch(() => props.data, (newData) => {
        if (newData.length > 0) {
            columns.value = Object.keys(newData[0]);
        }
        // Reset selected rows and go to first page when data changes
        selectedRows.value = [];
        currentPage.value = 1;
    }, { immediate: true, deep: true });

    watch(selectedRows, () => {
        // Emit the selected rows
        emit('RowsSelected', selectedRows.value);
    });

    watch(pageSize, () => {
        // Reset to first page when changing page size
        currentPage.value = 1;
    });


    // Computed properties
    const allSelected = computed(() => {
        return displayedData.value.length > 0 && 
               selectedRows.value.length === props.data.length;
    });

    const someSelected = computed(() => {
        return selectedRows.value.length > 0 && 
               selectedRows.value.length < props.data.length;
    });

    const totalPages = computed(() => {
        return Math.ceil(props.data.length / pageSize.value);
    });

    const paginatedData = computed(() => {
        const start = (currentPage.value - 1) * pageSize.value;
        const end = start + pageSize.value;
        return props.data.slice(start, end);
    });
    
    const displayedData = computed(() => {
        return props.pagination ? paginatedData.value : props.data;
    });


    // Methods
    const formatValue = (value) => {
        if (value === null || value === undefined || value === '') {
            return '--';
        }
        return value;
    }

    const toggleRowSelection = (index) => {
        const position = selectedRows.value.indexOf(index);
        if (position === -1) {
            selectedRows.value.push(index);
        } else {
            selectedRows.value.splice(position, 1);
        }
    }

    const toggleSelectAll = () => {
        if (allSelected.value) {
            selectedRows.value = [];
        } else {
            selectedRows.value = props.data.map((_, index) => index);
        }
    }

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages.value) {
            currentPage.value = page;
        }
    };

    const nextPage = () => {
        if (currentPage.value < totalPages.value) {
            currentPage.value++;
        }
    };

    const prevPage = () => {
        if (currentPage.value > 1) {
            currentPage.value--;
        }
    };

    const changePageSize = (size) => {
        pageSize.value = size;
    };

</script>

<template>
    <div class="bg-white rounded-md p-3 relative">
        <h1 v-if="title" class="text-lg font-bold mt-[10px] mb-[10px]">{{ title }}</h1>
        <div class="overflow-x-auto max-w-full">
            <!-- Loading overlay -->
            <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-10">
                <LoadingAnimation1 />
            </div>
            
            <table class="w-full bg-white border-collapse whitespace-nowrap">
                <thead>
                    <tr>
                        <th v-if="selectable" class="bg-sky-600 p-2 text-left w-10">
                            <input 
                                type="checkbox" 
                                :checked="allSelected"
                                :indeterminate="someSelected"
                                @change="toggleSelectAll"
                                class="w-4 h-4"
                            />
                        </th>
                        <th 
                            v-for="column in columns" 
                            :key="column" 
                            class="bg-sky-600 p-2 text-left min-w-32 max-w-[200px] overflow-hidden text-ellipsis"
                        >
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(row, rowIndex) in displayedData" 
                        :key="rowIndex"
                        :class="{'bg-slate-200': rowIndex % 2 !== 0}"
                    >
                        <td v-if="selectable" class="p-2 text-left w-10">
                            <input 
                                type="checkbox" 
                                :checked="selectedRows.includes(pagination ? ((currentPage - 1) * pageSize) + rowIndex : rowIndex)"
                                @change="toggleRowSelection(pagination ? ((currentPage - 1) * pageSize) + rowIndex : rowIndex)"
                                class="w-4 h-4"
                            />
                        </td>
                        <td 
                            v-for="column in columns" 
                            :key="column"
                            class="p-2 text-left min-w-32 max-w-[200px]"
                        >
                            <div class="truncate" :title="formatValue(row[column])">
                                {{ formatValue(row[column]) }}
                            </div>
                        </td>
                    </tr>
                    <tr v-if="displayedData.length === 0">
                        <td :colspan="selectable ? columns.length + 1 : columns.length" class="p-4 text-center text-gray-500">
                            No data available
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <!-- Pagination Controls - only show if pagination is enabled -->
            <div v-if="pagination" class="flex flex-wrap justify-between items-center mt-4 gap-2">
                <div class="flex items-center">
                    <span class="mr-2">Rows per page:</span>
                    <select 
                        v-model="pageSize" 
                        class="border rounded p-1 min-w-[60px]"
                        @change="changePageSize(parseInt($event.target.value))"
                    >
                        <option v-for="size in pageSizeOptions" :key="size" :value="size">
                            {{ size }}
                        </option>
                    </select>
                </div>
                
                <div>
                    Showing {{ props.data.length ? ((currentPage - 1) * pageSize) + 1 : 0 }} - 
                    {{ Math.min(currentPage * pageSize, props.data.length) }} 
                    of {{ props.data.length }} entries
                </div>
                
                <div class="flex space-x-1">
                    <button 
                        @click="prevPage" 
                        :disabled="currentPage === 1"
                        class="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                    >
                        Previous
                    </button>
                    
                    <button 
                        v-for="page in Math.min(5, totalPages)" 
                        :key="page"
                        @click="goToPage(page)"
                        :class="[
                            'px-3 py-1 border rounded hover:bg-gray-100', 
                            currentPage === page ? 'bg-sky-600 text-white hover:bg-sky-700' : ''
                        ]"
                    >
                        {{ page }}
                    </button>
                    
                    <span v-if="totalPages > 5 && currentPage < totalPages - 2" class="px-3 py-1">...</span>
                    
                    <button 
                        v-if="totalPages > 5 && currentPage < totalPages - 1"
                        @click="goToPage(totalPages)"
                        :class="[
                            'px-3 py-1 border rounded hover:bg-gray-100', 
                            currentPage === totalPages ? 'bg-sky-600 text-white hover:bg-sky-700' : ''
                        ]"
                    >
                        {{ totalPages }}
                    </button>
                    
                    <button 
                        @click="nextPage" 
                        :disabled="currentPage === totalPages || totalPages === 0"
                        class="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
