<!-- eslint-disable no-undef -->
<script setup>
    // Imports
    import { ref, watch, onMounted, computed } from 'vue';
    import JsonEditor from '@/components/common/JsonEditor.vue';
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';
    import action from '@/api/action';

    const props = defineProps({
        dataID: {
            type: String,
            required: true
        }
    });
    
    // References
    const selectedSetting = ref(null);
    const actionDetails = ref(null);
    const payload = ref({});
    const payloadValues = ref({});
    const editorVisible = ref(false);
    const loading = ref(false);
    const result = ref(null);
    const actions = ref({});

    // Lifecycle hooks
    onMounted(async() => {
        const response = await action.get(`/options?version=1`);
        if (response.status === 'success') {
            console.log(response.details);
            actions.value = response.details;
        }
    });

    // Watches
    watch(actionDetails, () => {
        result.value = null;
        editorVisible.value = false;
        
        // Reset payload with a delay to ensure proper re-rendering
        setTimeout(() => {
            setPayload();
        }, 100);
    });

    // Methods
    const filteredActions = computed(() => {
        if (!selectedSetting.value || !actions.value[selectedSetting.value]) return {};
        
        const result = {};
        Object.entries(actions.value[selectedSetting.value]).forEach(([key, value]) => {
            if (key !== 'name') {
                result[key] = value;
            }
        });
        return result;
    });

    const setPayload = async () => {
        if (actionDetails.value && actionDetails.value.payload) {
            
            // Payload is a string, so use it as is
            if (typeof actionDetails.value.payload === 'string') {
                payloadValues.value = {};
                payload.value = actionDetails.value.payload;
                editorVisible.value = false;
            } 
            
            // Payload is a valid JSON object, so start the editor
            else {
                const actionKey = Object.keys(actionDetails.value.payload)[0];
                payloadValues.value = actionDetails.value.payload[actionKey];
                editorVisible.value = true;
            }
        } else {
            payload.value = {};
            editorVisible.value = false;
        }
    }

    const sendRequest = async () => {
        loading.value = true;
        console.log(payloadValues.value);
        if (Object.keys(payloadValues.value).length) {
            const actionKey = Object.keys(actionDetails.value.payload)[0];
            payload.value = {
                [actionKey]: JSON.stringify(payloadValues.value)
            };
        }

        console.log(payload.value);
        let body = JSON.stringify({
            dataID: props.dataID,
            actionClass: selectedSetting.value,
            payload: payload.value
        });
        console.log("Sending payload:", payload.value);
        let response = null;
        if (actionDetails.value.method === "POST") {
            response = await action.post('', body);
        }
        else if (actionDetails.value.method === "GET") {
            response = await action.get(`?dataID=${props.dataID}&actionClass=${selectedSetting.value}&payload=${payload.value}`);
        }
        else if (actionDetails.value.method === "PUT") {
            response = await action.put('', body);
        }
        else if (actionDetails.value.method === "DELETE") {
            response = await action.delete('', body);
        }
        loading.value = false;

        if (response.status === 'success') {
            result.value = "Request sent";
        }
        else {
            result.value = "Request failed";
        }
    }
</script>

<template>
    <div class="bg-white p-4 rounded-lg w-full h-full flex flex-col">
        <h1 class="text-2xl font-bold underline">Action Request</h1>
        <form id="actions-request" class="p-4 w-full">
            <div class="w-full">
                <label class="block mb-2 w-full">
                    Select a setting
                    <select id="Subject" v-model="selectedSetting" class="w-full p-2 border rounded mt-1 bg-white text-black" @change="actionDetails = null" required>
                        <option v-for="(details, action) in actions" :key="action" :value="action">{{ details.name }}</option>
                    </select>
                </label>
                <label class="block mt-10 w-full">
                    What do you want to do?
                    <select id="Action" v-model="actionDetails" class="w-full p-2 border rounded mt-1 bg-white text-black" :disabled="!selectedSetting" @change="setPayload" required>
                        <option v-for="(details, action) in filteredActions" :key="action" :value="details">{{ action }}</option>
                    </select>
                </label>
                
                <!-- Always render the container, but conditionally show the editor -->
                <div id="payload-container" class="mt-10 min-h-[200px] w-full border-2 border-gray-300 rounded-lg p-4" 
                     :style="{ display: editorVisible ? 'block' : 'none' }">
                    <h2 class="font-bold mb-2">Payload:</h2>
                    <JsonEditor 
                        v-if="editorVisible"
                        v-model:json-value="payloadValues"
                        title="Payload"
                        :advanced="true"
                    />
                </div>
            </div>
            <div class="flex flex-row items-center gap-4 mt-4">
                <button :class="['default-button mt-10', { 'default-button-off': !actionDetails || loading}]" @click.prevent="sendRequest" :disabled="!actionDetails || loading">Send Request</button>
                <LoadingAnimation1 v-if="loading" class="mt-10" />
                <p v-if="result" class="mt-10">{{ result }}</p>
            </div>
        </form>
    </div>
</template>
