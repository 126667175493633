<template>
  <DefaultLayout
  title="Dashboard"
  >
    <div class="flex h-full">
      <div class="w-[455px] h-full flex flex-col">
        <div class="border-solid border-b-2 border-b-black dark:border-b-dark-text w-full m-[10px]">
          <h2 id="unitCount" class="text-xl font-semibold pb-3 pt-2 dark:text-dark-text">{{ unitCount }}</h2>
        </div>
        <div class="flex m-[10px]">
          <input id="searchbar" type="search" class="w-full " v-model="searchQuery" placeholder="Search Units..." @input="performSearch" />
        </div>
        <div class="flex flex-row justify-around w-full h-[50px] p-[5px]">
          <button id="all" @click="showAll()" :class="[activeShortcut === 'all' ? 'default-button-off' : 'default-button']">All</button>
          <button id="activated" @click="showActivated()" :class="[activeShortcut === 'activated' ? 'default-button-off' : 'default-button']">Activated</button>
          <button id="deactivated" @click="showDeactivated()" :class="[activeShortcut === 'deactivated' ? 'default-button-off' : 'default-button']">Deactivated</button>
          <button id="notDeployed" @click="showUnused()" :class="[activeShortcut === 'unused' ? 'default-button-off' : 'default-button']">Not Deployed</button>
        </div>

        <div v-if="searchResults.length > 0" :key="searchKey" class="flex-grow overflow-y-auto box-border" style="direction: rtl;">
          <UnitCard v-for="(map, index) in searchResults" :key="index"
            @click="toUnitDashboard(Array.from(map.keys())[0])"
            class="unitCard"
            :unitData="Array.from(map.values())[0]"
            :dataID="Array.from(map.keys())[0]"
            :timeFormat="timeFormat"
          ></UnitCard>
        </div>
        <div v-else :key="unitListKey" class="flex-grow overflow-y-auto box-border" style="direction: rtl;">
          <UnitCard v-for="(map, index) in unitsToDisplay" :key="index"
            @click="toUnitDashboard(Array.from(map.keys())[0])"
            class="unitCard"
            :unitData="Array.from(map.values())[0]"
            :dataID="Array.from(map.keys())[0]"
            :timeFormat="timeFormat"
          ></UnitCard>
        </div>
      </div>

      <div class="flex-grow bg-color-f dark:bg-color-f-darkmode">
        <GoogleMap :key="mapKey"
          :unitData="allUnits"
        ></GoogleMap>
      </div>
    </div>
  </DefaultLayout>
</template>
  
<script>
  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  // import { router } from '@/router';
  import { collection, query, where, getDocs } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { timeSimplified, utcToLocal } from '@/tools/TimeFunctions';

  import DefaultLayout from '@/layouts/Layout.vue';
  import UnitCard from '@/components/cards/UnitCard.vue';
  import GoogleMap from '@/components/maps/GoogleMap.vue';
  
  export default {
    name: 'MainDashboard',
    components: {
      DefaultLayout,
      UnitCard,
      GoogleMap
    },
    setup() {
      // Set up reference variables.
      const searchQuery = ref('');
      const searchResults = ref(new Array());
      const searchKey = ref(0);
      const allUnits = ref(new Map());
      const unitsToDisplay = ref(new Array());
      const unitListKey = ref(0);
      const unitCount = ref('');
      const mapKey = ref(1);
      const activeShortcut = ref("");

      // Grab stored values
      const router = useRouter();
      const store = useStore();
      const timeFormat = store.state.user.timeFormat;

      /**
       * Runs when the page loads and grabs all the units associated with
       * the user from the database and save it into the map.
       */
      const grabUnits = onMounted(async () => {
        try {
          // Grab the units from the database.
          const entity = store.state.user.entityViewing;
          const q = query(collection(db, "data"), where("entities", "array-contains-any", entity));
          const querySnapshot = await getDocs(q);

          // Save the data of each unit.
          for (const document of querySnapshot.docs) {
            allUnits.value.set(document.id, document.data());
          }

          showActivated();
        } catch (error) {
          console.log(error)
        }
        mapKey.value += 1
      });

      /**
       * Perform a search within the unit data
       */
      const performSearch = () => {
        const query = searchQuery.value;
        searchResults.value.length = 0;

        if (query) {
          for (let [key, value] of allUnits.value) {
            if (key.includes(query) || value.friendlyName.toLowerCase().includes(query.toLowerCase())) {
              searchResults.value.push(new Map([[key, value]]));
            }
          }
          searchKey.value += 1
        }
        
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showAll = () => {
        activeShortcut.value = "all";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          unitsToDisplay.value.push(new Map([[key, value]]));
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
      const showActivated = () => {
        activeShortcut.value = "activated";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (value.active) {
            const lastActive = timeSimplified(utcToLocal(value.lastActivityUTC, timeFormat));
            if (lastActive === 'Current') {
              unitsToDisplay.value.unshift(new Map([[key, value]]));
            }
            else if(lastActive) {
              unitsToDisplay.value.push(new Map([[key, value]]));
            }
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Activated = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showDeactivated = () => {
        activeShortcut.value = "deactivated";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (!value.active) {
            unitsToDisplay.value.push(new Map([[key, value]]));
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Deactivated = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showUnused = () => {
        activeShortcut.value = "unused";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (value.active && value.lastActivityUTC === "") {
            unitsToDisplay.value.push(new Map([[key, value]]));
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Undeployed = ${unitsToDisplay.value.length}`;
      };

      /**
       * Navigate the user to a unit dashboard
       * @param {string}  dataID  The data ID of a particular unit
       */
      const toUnitDashboard = (dataID) => {
        router.push(`/dashboard/${dataID}`);
      };

      return {
        searchQuery,
        searchResults,
        searchKey,
        allUnits,
        unitsToDisplay,
        unitListKey,
        unitCount,
        mapKey,
        grabUnits,
        timeFormat,
        performSearch,
        showAll,
        showActivated,
        showDeactivated,
        showUnused,
        activeShortcut,
        toUnitDashboard
      };
    }
  };
</script>
  
<style>

  .unit-list {
    height: 100%;
    overflow-y: auto;
    direction: rtl;
    box-sizing: border-box;
  }
</style>