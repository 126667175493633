class WebWorkerManager {
    constructor() {
        // Store workers in a Map with their unique identifiers
        this.workers = new Map();
    }

    // Create and register a new worker
    createWorker(workerId, WorkerModule) {
        if (this.workers.has(workerId)) {
            console.warn(`Worker with ID ${workerId} already exists`);
            return false;
        }

        try {
            // Create worker from the Vite worker module
            const worker = new WorkerModule();
            
            // Set up default error handling
            worker.onerror = (error) => {
                console.error(`Error in worker ${workerId}:`, error);
                console.error('Error message:', error.message);
                console.error('Error filename:', error.filename);
                console.error('Error line number:', error.lineno);
                console.error('Error column number:', error.colno);
            };

            this.workers.set(workerId, worker);
            return true;

        } catch (error) {
            console.error('Failed to create worker:', error);
            throw new Error(`Failed to create worker ${workerId}: ${error.message}`);
        }
    }

    // Get a worker by its ID
    getWorker(workerId) {
        const worker = this.workers.get(workerId);
        if (!worker) {
            throw new Error(`Worker with ID ${workerId} not found`);
        }
        return worker;
    }

    // Get a list of existing worker IDs
    getWorkers() {
        return Array.from(this.workers.keys());
    }

    // Send a message to a specific worker
    sendMessage(workerId, message) {
        const worker = this.getWorker(workerId);
        worker.postMessage(message);
    }

    // Add a message listener to a specific worker
    addMessageListener(workerId, callback) {
        const worker = this.getWorker(workerId);
        worker.onmessage = callback;
    }

    // Terminate a specific worker
    terminateWorker(workerId) {
        const worker = this.getWorker(workerId);

        // Send a cleanup message to the worker and wait for confirmation
        return new Promise((resolve) => {
            const cleanup = (e) => {
                if (e.data.status === 'cleaned') {
                    worker.removeEventListener('message', cleanup);
                    worker.terminate();
                    this.workers.delete(workerId);
                    resolve();
                }
            };

            worker.addEventListener('message', cleanup);
            worker.postMessage({ command: 'cleanup' });

            // Fallback: terminate after 5 seconds if cleanup doesn't happen
            setTimeout(() => {
                worker.terminate();
                this.workers.delete(workerId);
                resolve();
            }, 5000);
        });
    }

    // Terminate all workers
    terminateAll() {
        const terminationPromises = [];
        for (const [workerId] of this.workers) {
            terminationPromises.push(this.terminateWorker(workerId));
        }
        return Promise.all(terminationPromises);
    }

    // Check if a worker exists
    hasWorker(workerId) {
        return this.workers.has(workerId);
    }

    // Get the total number of active workers
    getWorkerCount() {
        return this.workers.size;
    }
}

// Export the manager
export default WebWorkerManager;