<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { ref, onMounted } from 'vue';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

  // Definitions
  const props = defineProps({
    columns: {
      required: true,
      type: Array,
      default: () => []
    },
    selectedColumns: {
      required: false,
      type: Array,
      default: () => []
    }
  });

  const emit = defineEmits(['selectedColumns', 'close']);

  // References
  const loading = ref(false);
  const newSelectedColumns = ref([...props.selectedColumns]); // Create a copy to avoid mutation
  const sortedColumnsList = ref([]);
  const draggedItem = ref(null);
  const draggedOverItem = ref(null);

  // Lifecycle Hooks
  onMounted(() => {
    // Sort columns once on mount
    sortedColumnsList.value = getSortedColumns();
  });

  // Methods
  const isColumnSelected = (column) => {
    return newSelectedColumns.value.includes(column);
  }

  const toggleColumn = (column) => {
    if (isColumnSelected(column)) {
      newSelectedColumns.value = newSelectedColumns.value.filter(id => id !== column);
    } else {
      newSelectedColumns.value.push(column);
    }
  }

  // Simplified sorting method
  const getSortedColumns = () => {
    return [...props.columns].sort((a, b) => {
      const aIndex = newSelectedColumns.value.indexOf(a);
      const bIndex = newSelectedColumns.value.indexOf(b);
      
      // If both are selected, sort by their order in selectedColumns
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      // If only one is selected, it comes first
      return aIndex !== -1 ? -1 : bIndex !== -1 ? 1 : 0;
    });
  }

  // Simplified drag and drop methods
  const handleDragStart = (e, column) => {
    draggedItem.value = column;
    e.target.classList.add('dragging');
  }

  const handleDragOver = (e, column) => {
    e.preventDefault();
    draggedOverItem.value = column;
    e.currentTarget.classList.add('drag-over');
  }

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('drag-over');
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    document.querySelectorAll('.dragging').forEach(el => el.classList.remove('dragging'));
    
    const draggedIndex = sortedColumnsList.value.indexOf(draggedItem.value);
    const targetIndex = sortedColumnsList.value.indexOf(draggedOverItem.value);
    
    if (draggedIndex !== -1 && targetIndex !== -1 && draggedIndex !== targetIndex) {
      // Reorder the sorted list
      const [removed] = sortedColumnsList.value.splice(draggedIndex, 1);
      sortedColumnsList.value.splice(targetIndex, 0, removed);
      
      // Update selected columns order if both items are selected
      if (isColumnSelected(draggedItem.value) && isColumnSelected(draggedOverItem.value)) {
        const selectedDraggedIndex = newSelectedColumns.value.indexOf(draggedItem.value);
        const selectedTargetIndex = newSelectedColumns.value.indexOf(draggedOverItem.value);
        
        const [selectedRemoved] = newSelectedColumns.value.splice(selectedDraggedIndex, 1);
        newSelectedColumns.value.splice(selectedTargetIndex, 0, selectedRemoved);
      }
    }
    
    draggedItem.value = null;
    draggedOverItem.value = null;
  }

  const handleDragEnd = (e) => {
    e.target.classList.remove('dragging');
    document.querySelectorAll('.drag-over').forEach(el => el.classList.remove('drag-over'));
  }

  const handleSubmit = () => {
    emit('selectedColumns', newSelectedColumns.value);
    emit('close');
  }

  const close = () => {
    emit('close');
  }
</script>

<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="close">
    <div class="bg-color-f p-[20px] rounded-lg w-[400px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class="font-extrabold text-xl underline text-center">Customize Columns</h2>
      <p class="font-normal text-center text-orange-600 border-b-2 border-gray-500 pb-2">WARNING: This will affect all devices that share the same template.</p>
      <br>
      <form @submit.prevent="handleSubmit">
        <!-- checkable column list with drag and drop -->
        <div class="flex flex-col gap-2 overflow-y-auto">
          <div 
            v-for="column in sortedColumnsList" 
            :key="column" 
            class="flex items-center gap-2 border-2 border-gray-300 rounded-md bg-gray-100 p-2 transition-all duration-200"
            :class="{
              'opacity-50': draggedItem === column,
              'border-dashed border-gray-600 bg-gray-200': draggedOverItem === column
            }"
            draggable="true"
            @dragstart="handleDragStart($event, column)"
            @dragend="handleDragEnd($event)"
            @drop="handleDrop($event)"
            @dragover="handleDragOver($event, column)"
            @dragleave="handleDragLeave($event)"
          >
            <div class="flex items-center gap-2 cursor-pointer flex-grow" @click.stop="toggleColumn(column)">
              <input 
                type="checkbox" 
                :id="column" 
                :checked="isColumnSelected(column)"
                @change="toggleColumn(column)"
                @click.stop
                class="w-4 h-4 cursor-pointer">
              <p :for="column" class="truncate max-w-[250px]" :title="column.replace(/_/g, ' ')">{{ column.replace(/_/g, ' ') }}</p>
            </div>
            <font-awesome-icon 
              :icon="['fas', 'grip-vertical']" 
              class="text-gray-500 cursor-grab active:cursor-grabbing"
            />
          </div>
        </div>

        <!-- Save and close -->
        <div class="flex justify-between mt-10">
          <button type="button" @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" :disabled="loading" class="default-button" :class="{'default-button-off': loading}">Save</button>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>
