<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { useStore } from 'vuex';
  import { ref } from 'vue';

  // Setup
  defineOptions({
    name: 'DefaultSidebar'
  })

  // References
  const isAdmin = ref(false);

  // Grab stored values
  const store = useStore();
  const user = store.state.user;

  if (user.role === 'admin') {
    isAdmin.value = true;
  }
</script>

<template>
  <aside class="sidebar absolute flex flex-col justify-between h-[calc(100vh-100px)] w-[60px] mt-[10px] rounded-tr-[10px] rounded-br-[10px] z-[49] transition-[width] duration-300 ease-in-out overflow-hidden hover:w-[150px] bg-color-a dark:bg-color-a-darkmode">
    <!-- Sidebar content starting from the top -->
    <div>
      <RouterLink to="/dashboard" class="flex items-center bg-transparent cursor-pointer overflow-hidden w-full pl-[10px] mt-[10px] border-b border-solid border-b-white dark:border-b-dark-text pb-[10px] hover:bg-color-a-darker">
        <img src="@/assets/images/home_white.png" alt="Home" class="w-[35px] h-[35px] rounded-full transition-[margin] duration-300 ease-in-out">
        <span class="opacity-0 ml-[10px] whitespace-nowrap transition-opacity duration-300 ease-in-out sidebar-hover:opacity-100 text-white dark:text-dark-text">Home</span>
      </RouterLink>
      <RouterLink to="/dashboard" class="flex items-center bg-transparent border-none cursor-pointer overflow-hidden w-full pl-[10px] mt-[20px] hover:bg-color-a-darker">
        <img src="@/assets/images/gauge_white.png" alt="Gauge" class="w-[35px] h-[35px] rounded-full transition-[margin] duration-300 ease-in-out">
        <span class="opacity-0 ml-[10px] whitespace-nowrap transition-opacity duration-300 ease-in-out sidebar-hover:opacity-100 text-white dark:text-dark-text">Dashboard</span>
      </RouterLink>
      <RouterLink to="/sim-cards" class="flex items-center bg-transparent border-none cursor-pointer overflow-hidden w-full pl-[10px] mt-[30px] hover:bg-color-a-darker">
        <font-awesome-icon :icon="['fas', 'sim-card']" class="w-[35px] h-[35px] rounded-full transition-[margin] duration-300 ease-in-out text-white" />
        <span class="opacity-0 ml-[10px] whitespace-nowrap transition-opacity duration-300 ease-in-out sidebar-hover:opacity-100 text-white dark:text-dark-text">Sim Cards</span>
      </RouterLink>
    </div>
  
    <!-- Settings icon at the bottom -->
    <div class="mb-[10px]">
      <RouterLink v-if="isAdmin" to="/settings" class="flex items-center bg-transparent border-none cursor-pointer overflow-hidden w-full pl-[10px] mt-[10px] hover:bg-color-a-darker">
          <img src="@/assets/images/gear_white.png" alt="Gear" class="w-[35px] h-[35px] rounded-full transition-[margin] duration-300 ease-in-out">
          <span class="opacity-0 ml-[10px] whitespace-nowrap transition-opacity duration-300 ease-in-out sidebar-hover:opacity-100 text-white dark:text-dark-text">Settings</span>
      </RouterLink>
    </div>
  </aside>
</template>
  
<style>
/* We only need this one custom modifier for the hover effect */
.sidebar:hover .sidebar-hover\:opacity-100 {
  opacity: 1;
}
</style>
