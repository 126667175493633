import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/sim_card';

class SimCard {
    static async get(path='', token=null) {
        // Handle regular REST API requests
        const response = await fetch(`${URL}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
            },
        });

        return response.json();
    }
}

export default SimCard;