<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { ref, onMounted, nextTick } from 'vue';
  import { TrashIcon } from '@heroicons/vue/24/solid';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
  // import SimpleDropdown from '../dropdowns/SimpleDropdown.vue';
  import Data from '@/api/data';
  import Calculation from '@/api/calculation';

  // Definitions
  const props = defineProps({
    dataID: {
      type: String,
      default: null
    },
    subcollectionOptions: {
      type: Object,
      default: () => ({})
    }
  });

  const emit = defineEmits(['calculation-details', 'close']);

  const loading = ref(false);
  const equationDOM = ref(null);
  const subcollections = ref(props.subcollectionOptions);
  const name = ref('');
  const unit = ref('');
  const equation = ref('');
  const startFrom = ref('');
  const variables = ref([{source: '', field: ''}]);
  const error = ref('');

  // Lifecycle hooks
  onMounted(async() => {
    if (!subcollections.value) {
      await getSubcollections();
    }
  });

  // Methods

  // Get the subcollections and their fields connected to the dataID.
  const getSubcollections = async() => {
    const response = await Data.get(`/options?dataID=${props.dataID}`);
    if (response.status === 'success') {
      // Ignore subcollections that are not relevant to the timeseries.
      const ignoreSubcollections = ['Net_Cfg', 'action_request', 'action_result', 'location', 'messages']
      subcollections.value = Object.fromEntries(
        Object.entries(response.details).filter(([key]) => !ignoreSubcollections.includes(key))
      );
    }
  }

  const insertAtCursor = (text) => {
    if (!equationDOM.value) return;
    
    const input = equationDOM.value;  // The actual DOM element
    const start = input.selectionStart;  // Can only get cursor position from DOM element
    const end = input.selectionEnd;
    const currentValue = equation.value; // The current text content
    
    equation.value = currentValue.substring(0, start) + text + currentValue.substring(end);
    
    nextTick(() => {
      input.setSelectionRange(start + text.length, start + text.length);
      input.focus();
    });
  };

  const validateEquation = () => {
    // Check for any invalid variable references.
    const variablePattern = /([a-zA-Z_][a-zA-Z0-9_]*)\.[a-zA-Z_][a-zA-Z0-9_]*/g;
    const matches = equation.value.match(variablePattern) || [];
    for (const match of matches) {
      const [source, field] = match.split('.');
      if (!variables.value.some(variable => variable.source === source && variable.field === field)) {
        error.value = `Invalid variable in equation: "${match}"`;
        return false;
      }
    }

    // Check if all defined variables are used in the equation.
    for (const variable of variables.value) {
      const expectedRef = `${variable.source}.${variable.field}`;
      if (!equation.value.includes(expectedRef)) {
        error.value = `Variable "${expectedRef}" is defined but not used in the equation.`;
        return false;
      }
    }

    // Everything checks out.
    return true;
  }

  const handleSubmit = async() => {
    loading.value = true;
    error.value = '';

    // Replace spaces with underscores in the name.
    const formattedName = name.value.replace(/\s+/g, '_');

    // Check if the equation is valid.
    if (!validateEquation()) {
      loading.value = false;
      return;
    }

    const response = await Calculation.post('/', {
      dataID: props.dataID,
      name: formattedName,
      equation: equation.value,
      unit: unit.value,
      startDate: startFrom.value,
      values: variables.value,
    });

    if (response.status !== 'success') {
      if (response.details?.error && response.details?.equation) {
        error.value = `${response.details.error}. It uses: ${response.details.equation}`;
      }
      else if (response.details?.error && response.details?.name) {
        error.value = `${response.details.error} under name: ${response.details.name}`;
      }
      else {
        error.value = response.details?.error || response.message;
      }
      
      loading.value = false;
      return;
    }

    // Add the new calculation to the subcollections.
    subcollections.value[formattedName] = {
      messageUTC: 'str',
      recieveUTC: 'str',
      value: 'float',
      unit: 'str',
    }

    // Emit the new calculation details and close the modal.
    emit('calculation-details', {name: name.value});
    close();
  }

  const close = () => {
    loading.value = false;
    emit('close');
  };
</script>

<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="close">
    <div class=" bg-color-f p-[20px] rounded-lg w-[600px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class=" font-extrabold text-xl underline text-center">Create A New Source</h2>
      <p class="font-normal text-center text-orange-600">WARNING: This will affect all devices that share the same template.</p>
      <br>
      <form @submit.prevent="handleSubmit">

        <!-- Collection name -->
        <label class="font-bold">
          Name:
          <br>
          <input v-model="name" type="text" class="w-full font-normal" required />
        </label>

        <!-- Variables -->
        <div v-for="(value, index) in variables" :key="index" class="mt-5 flex gap-2">
          <div v-if="variables.length > 1" class="flex gap-2 items-center hover:cursor-pointer pr-2 pt-5" @click="variables.splice(index, 1)">
            <TrashIcon class="h-[25px] w-[25px]"/>
          </div>

          <!-- Source -->
          <label class="font-bold">
              Source:
            <select :value="value.source" style="width: 100%;" @change="variables[index].source = $event.target.value" class="font-normal" required>
              <option v-for="subcollection in Object.keys(subcollections)" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
            </select>
          </label>

          <!-- field -->
          <label class="font-bold w-[175px] mr-3">
            Value: <span v-if="!value.source" class="font-normal text-red-600">(select source)</span>
            <select :value="value.field || ''" @change="variables[index].field = $event.target.value" :disabled="!value.source" style="width: 100%;" class="font-normal" required>
              <option v-for="field in Object.keys(subcollections[value.source] ?? []).filter(key => 
                subcollections[value.source][key] === 'int' || 
                subcollections[value.source][key] === 'float')" 
                :key="field" 
                :value="field">
                {{ field }}
              </option>
            </select>
          </label>

          <!-- Add to equation -->
          <button type="button" 
            v-if="value.source && value.field" 
            @click="insertAtCursor(`${value.source}.${value.field}`)" 
            class="default-button w-[60px] h-[40px] text-center p-0 mt-6">
            Insert in equation
          </button>
        </div>

        <!-- Add another variable -->
        <button type="button" @click="variables.push({source: '', field: ''})" class="text-color-a font-bold" :class="{'ml-10': variables.length > 1}">+ Add another value</button>

        <!-- Equation -->
        <div class="mt-5">
          <label class="font-bold">
            Equation:
            <span v-if="variables[0].field" class="font-normal ml-2">example = ({{ variables[0].source }}.{{ variables[0].field }} + 5) / 2</span>
            <input
              ref="equationDOM"
              v-model="equation" 
              type="text" 
              class="w-full font-normal" 
              required 
            />
          </label>
        </div>

        <!-- Unit -->
        <div class="mt-5">
          <label class="font-bold">
            Unit:
            <br>
            <input v-model="unit" type="text" class="w-full font-normal" required />
          </label>
        </div>

        <!-- Start From -->
        <div class="mt-5">
          <label class="font-bold">
            Start From:
            <br>
            <input v-model="startFrom" type="date" class="w-full font-normal" required />
          </label>
        </div>

        <!-- Error -->
        <div v-if="error" class="mt-5">
          <p class="font-normal ml-2 text-red-600 whitespace-pre-line">* {{ error }}</p>
        </div>

        <!-- Save and close -->
        <div class="flex justify-between mt-10">
          <button type="button" @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" :disabled="loading" class="default-button" :class="{'default-button-off': loading}">Create</button>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>
