<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-setup-props-destructure -->
<script setup>

    // Imports
    import { onMounted, ref, watch } from 'vue';
    import { TrashIcon } from '@heroicons/vue/24/solid';

    import { getPastTimestamp, localToUTC, utcToLocal } from '@/tools/TimeFunctions';
    import ChooseColumns from '@/components/modals/ChooseColumns.vue';
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';
    import DatePicker from '@/components/dropdowns/DatePicker.vue';
    import GenericTable from '@/components/tables/GenericTable2.vue';
    import Events from '@/api/event';

    // Definitions
    const emit = defineEmits(['columnUpdate', 'delete']);

    const props = defineProps({
        dataID: String,
        template: Object
    });

    // References
    const data = ref([]);
    const opperations = ref(null);
    const startDate = ref(null);
    const dateValue = ref([getPastTimestamp(24, 'local'), getPastTimestamp(0, 'local')]);
    const rangeSet = ref(false);
    const selectedEvents = ref([]);
    const loading = ref(null);
    const loadingEvents = ref(false);
    const error = ref(null);
    const allColumns = ref([]);
    const customizeColumns = ref(false);
    const tag = ref('');
    // Lifecycle Hooks
    onMounted(async() => {
        loadingEvents.value = true;
        await updateEvents();
        await getEvents();
        loadingEvents.value = false;
    });

    watch(props.template, async() => {
        loadingEvents.value = true;
        await updateEvents();
        await getEvents();
        loadingEvents.value = false;
    }, { deep: true });

    // Methods
    const updateEvents = async() => {
        const body = {
            dataID: props.dataID,
            name: props.template.title.replace(/\s+/g, '_')
        }
        const response = await Events.put('', body);
        console.log(response);
        
        if (response.status !== 'success') {
            error.value = "Failed to refresh events";
            return;
        }
    }

    const getEvents = async() => {
        console.log(dateValue.value);
        // Get the utc start and end dates.
        const utcStart = localToUTC(dateValue.value[0]);
        const utcEnd =  localToUTC(dateValue.value[1]);

        // The event title is connected to the event ID in the database. 
        // Format it to match the event ID.
        const eventName = props.template.title.replace(/\s+/g, '_');
        console.log(utcStart, utcEnd);
        // Get the events.
        const response = await Events.get(`?dataID=${props.dataID}&name=${eventName}&startDate=${utcStart}&endDate=${utcEnd}`);

        if (response.status !== 'success') {
            error.value = "Failed to retrieve events";
            return;
        }

        opperations.value = response.details.comparison;
        startDate.value = response.details.startDate;

        if (response.details.length === 0) {
            // const blankEvent = {};
            // for (const column of props.template.columns) {
            //     blankEvent[column] = null;
            // }
            // data.value.push(blankEvent);
            return;
        }

        // Grab all the keys from the first event.
        if (response.details.occurrences.length > 0) {
        allColumns.value = Object.keys(response.details.occurrences[0]);

            // Set up the event data to be displayed in the table.
            data.value = response.details.occurrences.map(event => {
                const formattedEvent = {};
                props.template.columns.forEach(column => {
                    if (column === 'startUTC') {
                        formattedEvent['Start Time'] = utcToLocal(event[column]);
                    } else if (column === 'endUTC') {
                        formattedEvent['End Time'] = utcToLocal(event[column]);
                    } else {
                        formattedEvent[column.replace(/_/g, ' ')] = event[column];
                    }
                });
                return formattedEvent;
            });
        } else {
            data.value = [];
        }
    };

    const currentEvents = async() => {
        dateValue.value = [getPastTimestamp(24, 'local'), getPastTimestamp(0, 'local')];
        loadingEvents.value = true;
        await updateEvents();
        await getEvents();
        loadingEvents.value = false;
    }

    const updateTag = async (action='') => {
        loading.value = null;
        loadingEvents.value = true;

        // Grab the start times of each selected event.
        const eventUTClist = []
        selectedEvents.value.forEach(event => {
            eventUTClist.push(localToUTC(data.value[event]['Start Time']));
        });

        // Query the API to update the tag.
        const response = await Events.put('/tag', {
            dataID: props.dataID,
            name: props.template.title.replace(/\s+/g, '_'),
            eventStartUTC: eventUTClist,
            tag: action === 'remove' ? '' : tag.value
        });

        if (response.status !== 'success') {
            error.value = "Failed to update tag";
            return;
        }

        tag.value = '';

        // Update the events and get the new events.
        await updateEvents();
        await getEvents();

        loadingEvents.value = false;
    };
</script>

<template>
    <div class="bg-color-f rounded-md p-3">
        <div class="flex flex-row justify-between">
            <div>
                <h1 class="mt-0 mb-1 text-2xl font-bold underline">{{ template.title }}</h1>
                <p v-if="opperations"><strong>Opperation:</strong> {{ opperations }}</p>
                <p v-if="startDate"><strong>Start Date:</strong> {{ startDate }}</p>
                <br>
                <div class="flex items-center mb-1">
                    <DatePicker v-model="dateValue" class="w-[420px] mr-5"></DatePicker>
                    <button :disabled="loading === 'setRange'" @click="getEvents" :class="{ 'bg-color-a-lighter': loading === 'setRange' }" class="default-button">Set Range</button>
                    <button v-if="rangeSet" @click="currentEvents" class="default-button">Back To Current</button>
                    <LoadingAnimation1 v-if="loading === 'setRange'" class="mx-5"></LoadingAnimation1>
                </div>
            </div>
            <LoadingAnimation1 v-if="loading === 'delete'" class="mx-5"></LoadingAnimation1>
            <div v-else class="flex gap-4">
                <font-awesome-icon :icon="['fas', 'rotate']" class="w-6 h-6 hover:cursor-pointer" @click="currentEvents"/>
                <TrashIcon class="w-6 h-6 hover:cursor-pointer" @click="loading = 'delete'; emit('delete')"/>
            </div>
        </div>
        <br>
        <div v-if="!rangeSet" class="flex flex-row justify-between min-h-10">
            <p>Most Recent Events</p>
            <div>
                <div v-if="selectedEvents.length > 0 && loading !== 'updateTag'">
                    <label>
                        Tag:
                        <br>
                        <input v-model="tag" class="border border-gray-300 rounded-md p-2 mr-2">
                    </label>
                    <button @click="updateTag" class="default-button h-8 mr-2">Add/Update Tag</button>
                    <button @click="updateTag('remove')" class="default-button h-8 mr-2">Remove Tag</button>
                </div>
                <button v-else class="default-button h-8 mr-2" @click="customizeColumns = true">Customize Columns</button>
                <LoadingAnimation1 v-if="loading === 'updateTag'" class="mx-5"></LoadingAnimation1>
            </div>
        </div>
        <GenericTable
            :data="data"
            :pagination="true"
            :selectable="true"
            :loading="loadingEvents"
            @RowsSelected="selectedEvents = $event"
        />
    </div>
    <ChooseColumns v-if="customizeColumns" 
        :columns="allColumns"
        :selectedColumns="template.columns"
        @selectedColumns="emit('columnUpdate', $event)"
        @close="customizeColumns = false"/>
</template>