<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable no-undef -->
<script setup>
  // Import components
  import { ref } from 'vue';
  import DefaultHeader from './Header.vue'
  import DefaultSidebar from './Sidebar.vue'
  import DefaultTitle from './Title.vue'
  import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue'

  // Definitions
  defineOptions({
    name: 'DefaultLayout'
  })

  const emit = defineEmits(['update:deviceDetails']);

  defineProps({
    dataID: String,
    title: String
  })

  // References
  const pageLoading = ref(true);

</script>

<template>
  <div class="h-screen w-screen bg-color-c dark:bg-color-c-darkmode">
    <DefaultHeader></DefaultHeader>
    <div class="flex">
      <DefaultSidebar></DefaultSidebar>
      <div class=" pt-[10px] ml-[70px] w-full">

        <DefaultTitle
          :dataID="dataID"
          :title="title"
          @update:deviceDetails="emit('update:deviceDetails', $event)"
          @update:ready="pageLoading = false"
        ></DefaultTitle>

        <main :style="[dataID ? 'height: calc(100vh - 193px);' : 'height: calc(100vh - 168px);']" class=" overflow-y-auto">
          <!-- Main content will go here -->
          <div v-if="pageLoading" class="flex items-center justify-center h-full">
            <LoadingAnimation1 class="w-[100px] h-[100px]"></LoadingAnimation1>
          </div>
          <slot v-else></slot>
        </main>
      </div>
    </div>
  </div>
</template>