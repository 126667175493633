import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/action';

class Action {
    static async get(path='', token=null) {
        // Handle regular REST API requests
        const response = await fetch(`${URL}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
        });

        return response.json();
    }

    static async post(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: body,
        });

        return response.json();
    }

    static async put(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: body,
        });
        return response.json();
    }

    static async delete(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: body,
        });
        return response.json();
    }
}

export default Action;