import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/data_test';

class Data {
    static async get(path, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async post(path, body, token=null) {
        if (path.includes('stream')) {
            const controller = new AbortController();
            const response = await fetch(`${URL}${path}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream'
                },
                body: JSON.stringify(body),
                signal: controller.signal
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            return {
                stream: true,
                async read(callback) {
                    try {
                        let buffer = '';
                        // eslint-disable-next-line no-constant-condition
                        while (true) {
                            const { done, value } = await reader.read();
                            
                            if (done) {
                                callback({
                                    body: {
                                        status: 'complete'
                                    }
                                });
                                break;
                            }

                            // Log the raw data received from the stream
                            const rawData = decoder.decode(value);
                            
                            // First try to parse the entire raw data as a complete JSON object
                            try {
                                const parsed = JSON.parse(rawData);
                                callback(parsed);
                                continue; // Skip the rest of the loop if we successfully parsed the raw data
                            } catch (error) {
                                // If direct parsing fails, continue with the existing buffer approach
                                buffer += rawData;
                                // Process all complete JSON objects in the buffer
                                let startIndex = 0;

                                // eslint-disable-next-line no-constant-condition
                                while (true) {
                                    // Find the next complete JSON object
                                    const nextStart = buffer.indexOf('{"statusCode"', startIndex);
                                    if (nextStart === -1) break;
                                    
                                    // Find the end of this JSON object - look for the last }} in the buffer
                                    const nextEnd = buffer.lastIndexOf('}}');
                                    if (nextEnd === -1) break;
                                    
                                    const possibleMessage = buffer.substring(nextStart, nextEnd + 2);
                                    
                                    try {
                                        const parsed = JSON.parse(possibleMessage);
                                        callback(parsed);
                                        startIndex = nextEnd + 2;
                                        break; // Exit after successful parse
                                    } catch (error) {
                                        // If parsing fails, move to next potential JSON object
                                        startIndex = nextStart + 1;
                                    }
                                }
                                
                                // Keep only the unprocessed part of the buffer
                                if (startIndex > 0) {
                                    buffer = buffer.substring(startIndex);
                                }
                            }
                        }
                    } catch (error) {
                        if (error.name === 'AbortError') {
                            return; // Exit gracefully on abort
                        }
                        throw error;
                    }
                },
                cancel() {
                    controller.abort();
                    reader.cancel().catch(console.error);
                }
            };
        }
        else {
            // Regular POST request
            const response = await fetch(`${URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });
            return response.json();
        }
    }
}

export default Data;