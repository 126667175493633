<template>
    <div class="google-map" ref="mapContainer"></div>
    <div ref="control" class="map-control">
        <button @click="recenterMap">Recenter</button>
    </div>
</template>
    
<script>
    import { onMounted, ref, nextTick } from 'vue';
    import { router } from '@/router';
    import { MarkerClusterer } from "@googlemaps/markerclusterer";
    import { timeSimplified, utcToLocal } from '@/tools/TimeFunctions';

    //Set up Google Maps
    (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise((f,n)=>{a=m.createElement("script");e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once."):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        key: "AIzaSyAdnZ7SDSCwfssmTuoztLkglh9w06vbPg8",
        v: "weekly",
    });

    /* global google */
    export default {
        name: 'GoogleMap',
        props: {
            unitData: {
                type: Map,
                default: new Map()
            },
        },
        setup(props) {
            const mapContainer = ref(null);
            const control = ref(null);
            const markers = new Array();
            let map;
            let bounds;

            onMounted( async () => {
                // Wait for the next tick to ensure the DOM is fully rendered
                await nextTick();
                
                // Request needed libraries.
                const { Map, InfoWindow } = await google.maps.importLibrary("maps");
                const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary( "marker" );
                
                // Initiate the Map
                map = new Map(mapContainer.value, {
                    zoom: 5,
                    center: { lat: 39.22696383208982, lng: -98.91715880586187 },
                    mapId: "19e9c359663114f4",
                    mapTypeId: google.maps.MapTypeId.HYBRID,
                });
                
                // Initiate the info window for markers
                const infoWindow = new InfoWindow({
                    content: "",
                    disableAutoPan: true,
                });

                // Initiate the bounds to zoom on all markers
                bounds = new google.maps.LatLngBounds();

                // Close the info window if clicked off on the map
                google.maps.event.addListener(map, "click", () => {
                    if (infoWindow) {
                        infoWindow.close();
                    }
                });

                // Add a recenter button to the map
                const controlDiv = document.createElement('div');
                controlDiv.appendChild(control.value);
                map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);
                
                // Add some markers to the map.
                for (let [key, value] of props.unitData) {
                    
                    const data = value;
                    const unitID = data.friendlyName || key;

                    // console.log(data.active);

                    if (data.currentLat === '' || !value.active) {
                        continue;
                    }

                    const position = {
                        lat: data.currentLat,
                        lng: data.currentLon
                    };

                    // Create an element for a green marker
                    const greenPin = new PinElement({
                        background: '#00ff00',
                        borderColor: '#00ff00',
                        glyphColor: "#0AA900"
                    });

                    // Generate the marker
                    const marker = new AdvancedMarkerElement({
                        position,
                    });

                    // Make the marker green if unit is currently active
                    if (timeSimplified(utcToLocal(data.lastActivityUTC)) === 'Current') {
                        marker.content = greenPin.element;
                    }

                    // open info window when marker is clicked
                    marker.addListener("click", () => {
                        infoWindow.setContent(`
                            <h1 class="font-bold text-lg">${unitID}</h2><br>
                            <button id="viewUnitDashboardButton" class="default-button">Unit Dashboard</button><br><br>
                            <span class="font-bold">Latitude: </span>${data.currentLat}<br>
                            <span class="font-bold">Longitude: </span>${data.currentLon}<br>
                            <span class="font-bold">Last Active: </span>${utcToLocal(data.lastActivityUTC)}
                        `);
                        infoWindow.open(map, marker);

                        // Add event listener to the button after InfoWindow is opened
                        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
                            document.getElementById('viewUnitDashboardButton').addEventListener('click', () => {
                                router.push(`/dashboard/${key}`);
                            });
                        });
                    });

                    // Add the bounds of the marker
                    bounds.extend(position);
                    
                    markers.push(marker);
                }

                if (markers.length) {
                    // Set the bounds
                    map.fitBounds(bounds);

                    // Add a marker clusterer to manage the markers.
                    new MarkerClusterer({ markers, map });
                }
            });

            const recenterMap = () => {
                map.fitBounds(bounds);
            };

            return {
                mapContainer,
                control,
                recenterMap,
            };
        },
    };
</script>
    
<style>
 .google-map {
    height: 100%;
 }

 .map-control {
    margin-top: 10px;
 }

 .map-control button {
    height: 40px;
    font-size: 17px;
    font-weight: bold;
    padding: 12px;
    background-color: white;
 }
</style>