<script setup> 
    // Imports
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import DefaultLayout from '@/layouts/Layout.vue';
    import SimCard from '@/api/simCard';
    import GenericTable2 from '@/components/tables/GenericTable2.vue';
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';

    // References
    const simCards = ref([]);
    const loading = ref(false);

    const store = useStore();
    const entity = store.state.user.entityViewing[0];

    // Lifecycle Hooks
    onMounted(async () => {
        await getSimCards();
    });

    // Methods
    const getSimCards = async () => {
        loading.value = true;
        const response = await SimCard.get(`/?entity=${entity}`);
        if (response.status === 'success') {
            simCards.value = response.details;
            loading.value = false;
        }
        else {
            loading.value = false;
            await new Promise(resolve => setTimeout(resolve, 500));
            alert("Faild to retrieve sim cards");
        }
    }
</script>

<template>
    <DefaultLayout
        title="Sim Cards"
    >
        <div v-if="loading" class="flex justify-center mt-[100px] h-full w-full">
            <LoadingAnimation1 class="w-[100px] h-[100px]" />
        </div>
        <div v-else class="mt-[50px]">
            <div class="flex justify-end mb-[10px]">
                <button class="default-button mr-[10px]" @click="getSimCards">Refresh</button>
            </div>
            <GenericTable2
                :title="`COUNT: ${simCards.length}`"
                :data="simCards"
            />
        </div>
    </DefaultLayout>
</template>