<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { doc, getDoc } from 'firebase/firestore';

  import { db } from '@/firebase';
  import { timeSimplified, utcToLocal } from '@/tools/TimeFunctions';
  import Device from '@/api/device';

  // Definitions
  defineOptions({
    name: 'DefaultTitle'
  })

  const emit = defineEmits(['update:deviceDetails', 'update:ready']);

  const props = defineProps({
    dataID: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false,
      default: 'MeshPortal'
    }
  })

  // References
  const internalTitle = ref(props.title);
  const subtitle = ref(null);
  const links = ref(new Map());
  const subtitleUpdateInterval = ref(null);
  const error = ref(null);
  const templateData = ref(null);
  const deviceDetails = ref(null);
  const unsubscribeStream = ref(null);

  const router = useRouter();
  const store = useStore();
  const timeFormat = store.state.user.timeFormat;

  // Lifecycle hooks
  onMounted(async () => {
    if (props.dataID) {
      try {
        // Check if the device details are already in the store
        deviceDetails.value = store.state.device;
        if (!deviceDetails.value || deviceDetails.value.dataID !== props.dataID) {
            deviceDetails.value = await getDeviceDetails();
        } else {
          getDeviceDetails();
        }

        // Check if the template is already in the store
        templateData.value = store.state.template;
        if (!templateData.value || templateData.value.dataID !== props.dataID) {
          templateData.value = await getTemplate();
        } else {
          getTemplate();
        }

        setSubtitle();
        setSubLinks();
        emit('update:ready');
        emit('update:deviceDetails', deviceDetails.value);

        const { unsubscribe } = await Device.get(`/stream/?dataID=${props.dataID}`);
        unsubscribeStream.value = unsubscribe;

        // Set the callback to handle future updates
        unsubscribe.setCallback((update) => {
          if (update.error) {
            error.value = update.error;
            console.error('Error in device stream:', update.error);
          } else {
            // Update the device details and subtitle when new data arrives
            deviceDetails.value = update.details;
            setSubtitle();
          }
        });
      } catch (err) {
        error.value = err;
        console.error('Error fetching device details:', err);
      }
    }

    emit('update:ready');
  })

  onUnmounted(() => {
    if (subtitleUpdateInterval.value) {
      clearInterval(subtitleUpdateInterval.value);
    }
    if (unsubscribeStream.value) {
      unsubscribeStream.value();
    }
  })

  // Methods

  const getDeviceDetails = async () => {
    const response = await Device.get(`?dataID=${props.dataID}`);
    const device = response.details;
    device.dataID = props.dataID;
    store.dispatch('setDevice', device);
    return device;
  }

  const getTemplate = async () => {
    try {
      const docSnap = doc(db, "templates", deviceDetails.value.defaultTemplate);
      const template = await getDoc(docSnap);

      if (template.exists()) {
        const data = template.data();
        data.id = template.id;
        data.dataID = props.dataID;

        // Store the template in vuex
        store.dispatch('setTemplate', data);
        
        // Return the data so it can be assigned to templateData.value
        return data;
      }
      return null;  
    } catch(error) {
      console.error('Failed to fetch template:', error);
      return null;
    }
  };

  const setSubLinks = () => {
    // Add a guard to ensure templateData.value exists
    if (!templateData.value) return;
    
    // Set up the subpage links
    const path = router.currentRoute.value.path;
    links.value.set('Overview', [`/dashboard/${props.dataID}`, path === `/dashboard/${props.dataID}` ? 'active' : 'inactive']);

    if (templateData.value.timeSeries) {
        links.value.set('Time Series', [`/dashboard/${props.dataID}/time-series`, path === `/dashboard/${props.dataID}/time-series` ? 'active' : 'inactive']);
    }

    if (templateData.value.events) {
        links.value.set('Events', [`/dashboard/${props.dataID}/events`, path === `/dashboard/${props.dataID}/events` ? 'active' : 'inactive']);
    }

    links.value.set('Management', [`/dashboard/${props.dataID}/management`, path === `/dashboard/${props.dataID}/management` ? 'active' : 'inactive']);
  }

  const setSubtitle = () => {
    if (deviceDetails.value.friendlyName !== "") {
      internalTitle.value = deviceDetails.value.friendlyName;
      subtitle.value = `
        <p>
          <span style="font-weight: bold">Data ID: </span>
          ${props.dataID}
          <span style="font-weight: bold; margin-left: 20px;">Last Active: </span>
          ${timeSimplified(utcToLocal(deviceDetails.value.lastActivityUTC, timeFormat))}
        </p>
      `.trim();
    } else {
      internalTitle.value = props.dataID;
      subtitle.value = `
        <p>
          <span style="font-weight: bold;">Last Active: </span>
          ${timeSimplified(utcToLocal(deviceDetails.value.lastActivityUTC, timeFormat))}
        </p>
      `.trim();
    }
  }

</script>

<template>
  <div class="flex justify-between h-fit mb-[10px] bg-color-e-darker" :class="[subtitle ? 'h-[100px]' : 'h-[65px]']">

    <!--Create the page title and subtitle-->
    <div class="inline-block">
      <h1 class="text-3xl font-bold m-[10px]">{{ internalTitle }}</h1>
      <div v-if="subtitle" v-html="subtitle" class="ml-[10px]"></div>
    </div>

    <!--Create links to subpages-->
    <div v-if="links" class="self-end mr-20 mb-[6px]" role="navigation">
      <router-link 
        v-for="[pageName, address] in links" 
        :key="pageName"
        :to="address[0]"
        :class="[address[1] === 'inactive' ? 'bg-color-c-darker cursor-pointer' : 'bg-color-c cursor-default dark:bg-color-c-darkmode dark:text-dark-text']" 
        class="p-2 ml-2 rounded-t-lg"
        :aria-current="address[1] === 'active' ? 'page' : undefined"
      >
        {{ pageName }}
      </router-link>
    </div>

  </div>
</template>