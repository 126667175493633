import { createStore } from 'vuex';

export default createStore({
  state: {
    user: JSON.parse(sessionStorage.getItem('user')) || null,
    device: JSON.parse(sessionStorage.getItem('device')) || null,
    template: JSON.parse(sessionStorage.getItem('template')) || null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      sessionStorage.setItem('user', JSON.stringify(user));
    },
    setDevice(state, device) {
      state.device = device;
      sessionStorage.setItem('device', JSON.stringify(device));
    },
    setTemplate(state, template) {
      state.template = template;
      sessionStorage.setItem('template', JSON.stringify(template));
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setDevice({ commit }, device) {
      commit('setDevice', device);
    },
    setTemplate({ commit }, template) {
      commit('setTemplate', template);
    }
  },
  getters: {
    getUser: (state) => state.user,
    getDevice: (state) => state.device,
    getTemplate: (state) => state.template
  },
});