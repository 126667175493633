<template>
    <Menu as="div" class="relative inline-block text-left -m-3 text-white">
          <MenuButton>
            <img src="@/assets/images/default-avatar.png" alt="Profile" class="w-10 h-10 rounded-full">
            <p class="text-sm">{{ username }}</p>
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="px-4 py-3">
                <p class="text-sm">Signed in as</p>
                <p class="truncate text-sm font-medium text-gray-900">{{ userEmail }}</p>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }" v-if="permissions === 'admin'">
                  <a href="../settings" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Account settings</a>
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Support</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">License</a>+
                </MenuItem> -->
              </div>
              <div class="py-1">
            <form action="#" @submit.prevent="signOut">
              <MenuItem v-slot="{ active }">
                <button type="submit" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full px-4 py-2 text-left text-sm']">Sign out</button>
              </MenuItem>
            </form>
          </div>
        </MenuItems>
      </transition>
    </Menu>
</template>

<script>
import { auth } from '@/firebase';
import { useStore } from 'vuex';
import { router } from '@/router/index';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ref } from 'vue';

export default {
    name: "DropdownWithHeader",
    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    },
    props: {
        username: String,
        userEmail: String
    },
    setup() {

      const store = useStore();
      const user = store.state.user;

      const permissions = ref(user.role);

      const signOut = async () => {
        await auth.signOut();
        store.dispatch('setUser', null);
        router.push({ name: 'Login' });
      };

      return {
        permissions,
        signOut
      };
    },
};
</script>