import { createApp } from 'vue';
import App from './App.vue';

// Import the router and store
import router from '@/router';
import store from '@/store';

// Import the styles
import './assets/styles/output.css';

// Import the Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEdit, faSimCard, faRotate, faGripVertical } from '@fortawesome/free-solid-svg-icons';

// Font Awesome Icons
library.add(faEdit);
library.add(faSimCard);
library.add(faRotate);
library.add(faGripVertical);

// Create app instance
const app = createApp(App);

// Register global components
app.component('font-awesome-icon', FontAwesomeIcon);

// Use plugins
app.use(store);
app.use(router);

// Mount the app
app.mount('#app');
