<script setup>
    // Imports
    import { onMounted, onUnmounted, ref, nextTick, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import { ArrowDownTrayIcon } from '@heroicons/vue/24/solid';
    import { getPastTimestamp, localToUTC } from '@/tools/TimeFunctions';
    import { auth } from '@/firebase';

    import DefaultLayout from '@/layouts/Layout.vue';
    import TimeSeries from '@/components/charts/TimeSeries.vue'
    import UpdateTemplate from '@/components/modals/updateTemplate.vue';
    import DatePicker from '@/components/dropdowns/DatePicker.vue';
    import WebWorkerManager from '@/WebWorkers/workerManager';
    import TimeseriesWorker from '@/WebWorkers/timeseriesData.worker.js';
    import Template from '@/api/template';
    import Calculation from '@/api/calculation';

    // Set up reference variables
    const store = useStore();

    const fullTemplate = ref(null);
    const timeseriesTemplates = ref(null);
    const data = ref({});
    const isLive = ref(true);
    const timeRange = ref([]);
    const sliderRange = ref([]);
    const historicalRange = ref([]);
    const modal = ref(null);
    const timedOut = ref(false);

    const verticalLine = ref(null);
    const chartContainer = ref(null);
    const leftBoundary = 100;
    const rightBoundary = 85;
    const isLineFrozen = ref(false);
    const frozenPosition = ref(null);
    const showVerticalLine = ref(true);
    const previousVerticalLineState = ref(showVerticalLine.value);

    // Set up all other variables
    const workerManager = new WebWorkerManager();
    const route = useRoute();
    const dataID = route.params.dataID;
    let isZooming = false;

    // Lifecycle hooks
    onMounted(async () => {
        // Grab the template from the store
        fullTemplate.value = store.state.template;

        // Update the time range
        updateTimeRange();
        
        // Wait for the next tick to ensure the DOM is fully rendered
        await nextTick();
        
        if (chartContainer.value) {
            // Add event listeners
            chartContainer.value.addEventListener('mousemove', handleMouseMove);
            chartContainer.value.addEventListener('mouseleave', handleMouseLeave);
            chartContainer.value.addEventListener('click', handleClick);
        } else {
            console.error("chartContainer ref is not available on mount");
        }
    });

    onUnmounted(() => {
        if (chartContainer.value) {
            // Clean up event listeners
            chartContainer.value.removeEventListener('mousemove', handleMouseMove);
            chartContainer.value.removeEventListener('mouseleave', handleMouseLeave);
            chartContainer.value.removeEventListener('click', handleClick);
        }

        workerManager.terminateAll();
    });


    // Methouds
    const setupWorkers = async () => {
        // Terminate all workers
        await workerManager.terminateAll();

        // Create a worker for each key in the data object
        for (const key of Object.keys(data.value)) {

            const workerID = `${key}-retrieval`;
            try {
                // Create the worker and store it in the workerManager
                const created = workerManager.createWorker(workerID, TimeseriesWorker);

                if (created) {
                    // Set up the message handler for this worker
                    workerManager.addMessageListener(workerID, (event) => {

                        // If were live, update the timerange of the timeseries chart every time
                        // a new message is received. (about every 30 seconds)
                        if (isLive.value) {
                            updateTimeRange();
                        }

                        // Update the timeseries charts with the new data
                        if (event.data.status === 'success') {
                            data.value[key] = event.data.payload;

                        // If the stream is complete, we are no longer live.
                        // Each stream lasts about 9 minutes.
                        } else if (event.data.status === 'complete') {
                            if (isLive.value) {
                                timedOut.value = true;
                                showVerticalLine.value = false;
                            }

                        // If the stream is in an error state.
                        } else if (event.data.status === 'error') {
                            console.error('Worker reported error:', event.data.payload);
                        }
                    });
                }
            } catch (error) {
                console.error(`Failed to create worker ${workerID}:`, error);
            }
        }
    };

    const startStream = async (type = 'live') => {
        timedOut.value = false;
        showVerticalLine.value = previousVerticalLineState.value;
        // Clear any existing data
        for (const key of Object.keys(data.value)) {
            data.value[key] = {'x': []};
        }

        // Grab the token
        const token = await auth.currentUser.getIdToken();

        // Build the message
        const message = {
            command: 'stream',
            token: token,
            body: {
                dataID: dataID,
                subject: '',
                filters: []
            }
        };

        // If were live, start the stream from the past 24 hours
        if (type === 'live') {
            isLive.value = true;
            message.body.filters = [
                {
                    field: 'messageUTC',
                    operator: '>=',
                    value: getPastTimestamp(24)
                }
            ]
        }

        // If were historical, set the historical range
        else {
            message.body.filters = [
                {
                    field: 'messageUTC',
                    operator: '>=',
                    value: localToUTC(historicalRange.value[0])
                },
                {
                    field: 'messageUTC',
                    operator: '<=',
                    value: localToUTC(historicalRange.value[1])
                }
            ]
        }

        try {
            // Start the stream for each key in the data object
            for (const key of Object.keys(data.value)) {
                const workerID = `${key}-retrieval`;

                // Update any calculations that need to be updated.
                updateCalculation(key);

                // Stop and cleanup any existing streams
                await new Promise(resolve => {
                    workerManager.sendMessage(workerID, { command: 'cleanup' });
                    setTimeout(resolve, 1000);
                });

                // Set the subject to the key
                message.body.subject = key;
                
                // Send the message to the worker
                try {
                    workerManager.sendMessage(workerID, message);
                } catch (error) {
                    console.error(`Failed to start stream for ${workerID}:`, error);
                }
            }
        } catch (error) {
            console.error('Failed to start streams:', error);
        }
    };

    const handleModal = (modalState) => {
        if (modalState === 'open') {
            previousVerticalLineState.value = showVerticalLine.value;
            showVerticalLine.value = false;
            isLineFrozen.value = false;
            frozenPosition.value = null;
        } else {
            modal.value = null;
            showVerticalLine.value = previousVerticalLineState.value;
        }
    };

    const updateTimeRange = () => {
        sliderRange.value[0] = getPastTimestamp(24, 'local');
        sliderRange.value[1] = getPastTimestamp(0, 'local');

        if (!isZooming) {
            timeRange.value = sliderRange.value;
        }
    };

    const onUpdateTimeseries = async (index, template) => {

        const newTemplate = fullTemplate.value;
        newTemplate.timeSeries.charts[index] = template;

        // Update the template stored in the database
        const body = {
            templateID: fullTemplate.value.id,
            template: Object.fromEntries(
                Object.entries(newTemplate).filter(([key]) => key !== 'id' && key !== 'dataID')
            )
        };
        const response = await Template.put(`/`, body);

        if (response.status !== 'success') {
            alert('Failed to update the template.');
            return;
        }

        // Update the local template
        fullTemplate.value = newTemplate;

        // Update the template in the store
        store.dispatch('setTemplate', newTemplate);
    };

    const onAddTimeseries = async (details) => {
        // Build the template structure
        const newTimeseries = {
            title: details.title,
            type: "timeseries",
            specifications: {}
        };

        for (const specification of details.specifications) {
            const { source, ...remainingDetails } = specification;
            if (source in newTimeseries.specifications) {
                newTimeseries.specifications[source].push(remainingDetails);
            } else {
                newTimeseries.specifications[source] = [remainingDetails];
            }
        }

        // Add the new timeseries to the template stored in the database   
        const newTemplate = fullTemplate.value;
        newTemplate.timeSeries.charts.push(newTimeseries);

        const body = {
            templateID: fullTemplate.value.id,
            template: Object.fromEntries(
                Object.entries(newTemplate).filter(([key]) => key !== 'id' && key !== 'dataID')
            )
        };
        const response = await Template.put(`/`, body);

        if (response.status !== 'success') {
            alert('Failed to update the template.');
            return;
        }

        console.log("adding new timeseries to the template");
        // Update the local template
        fullTemplate.value = newTemplate;
        console.log(fullTemplate.value);

        console.log("updating the template in the store");
        // Update the template in the store
        store.dispatch('setTemplate', fullTemplate.value);
        console.log("template updated in the store");
    };

    const onDeleteTimeSeries = async (index) => {
        // Wait for the next tick to ensure all child components are stable
        await nextTick();

        // Create a deep copy of the full template
        const updatedTemplate = fullTemplate.value;
        
        // Remove the chart at the specified index
        updatedTemplate.timeSeries.charts.splice(index, 1);

        console.log("updatedTemplate", updatedTemplate);
        
        // Create the body with the updated template, removing both id and dataID
        const body = {
            templateID: fullTemplate.value.id,
            template: Object.fromEntries(
                Object.entries(updatedTemplate).filter(([key]) => key !== 'id' && key !== 'dataID')
            )
        };
        
        const response = await Template.put(`/`, body);

        if (response.status !== 'success') {
            alert('Failed to update the template.');
            return;
        }

        // Update the local template
        fullTemplate.value = updatedTemplate;

        // Update the template in the store
        store.dispatch('setTemplate', updatedTemplate);
    };

    const onHandleHistorcialData = async () => {
        // Let the code know were no longer live
        isLive.value = false;

        // Set the time range to the historical range
        timeRange.value = historicalRange.value;

        // Start a historical stream
        // (Currently, this is our best option as responses are limited to 
        // 50 documents at a time. 500,000 doc = 10,000 API calls or 1 api stream)
        startStream('historical');
    };

    const handleRelayout = (event) => {
        // console.log("Relayout event: ", event);

        // Check if a range selector was pressed or a zoom event occurred
        if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {

            // Check if the 24hr button was pressed
            const rangeDuration = new Date(event['xaxis.range[1]']) - new Date(event['xaxis.range[0]']);
            if (rangeDuration === 86400000) {
                isZooming = false;
            }

            // A different zoom event occurred, Pause any time range updates
            else {
                isZooming = true;
                timeRange.value = [event['xaxis.range[0]'], event['xaxis.range[1]']];
            }

            updateTimeRange();
        }

        // Check if the range slider was used
        else if (Object.keys(event).length === 1 && event['xaxis.range']) {

            // Check if the Time Range is back to 24 hours. If so, update the time range and resume updates.
            const rangeDuration = new Date(event['xaxis.range'][1]) - new Date(event['xaxis.range'][0]);
            if (rangeDuration === 86400000) {
                isZooming = false;
            }

            // A different zoom event occurred, Pause any time range updates
            else {
                isZooming = true;
                timeRange.value = [event['xaxis.range'][0], event['xaxis.range'][1]];
            }

            updateTimeRange();
        }
    }

    const onRequestDownload = () => {
        // First, initialize data structure and collect all timestamps
        const combinedData = {
            "timestamp UTC": new Set(),
        };

        // Initialize arrays for wanted fields
        timeseriesTemplates.value.forEach(template => {
            Object.values(template.specifications).forEach(specifications => {
                specifications.forEach(spec => {
                    combinedData[spec.friendlyName] = [];
                });
            });
        });

        // Collect timestamps from all subcollections
        Object.values(data.value).forEach(subcollectionData => {
            subcollectionData.x.forEach(timestamp => combinedData["timestamp UTC"].add(timestamp));
        });

        // Convert timestamp Set to sorted array and populate values
        combinedData["timestamp UTC"] = Array.from(combinedData["timestamp UTC"]).sort();
        
        // Second, Populate values for each timestamp
        combinedData["timestamp UTC"].forEach(timestamp => {
            for (const template of timeseriesTemplates.value) {
                for (const [subcollection, specifications] of Object.entries(template.specifications)) {
                    const index = data.value[subcollection].x.indexOf(timestamp);
                    specifications.forEach(spec => {
                        combinedData[spec.friendlyName].push(
                            index !== -1 ? data.value[subcollection][spec.field][index] : null
                        );
                    });
                }
            }
        });
        
        // Third, download the CSV
        downloadCSV(combinedData);
    };

    const downloadCSV = (combinedData) => {
        // Create headers and rows
        const headers = Object.keys(combinedData);
        const rows = [headers.join(',')];

        // Add data rows
        for (let i = 0; i < combinedData["timestamp UTC"].length; i++) {
            rows.push(headers.map(header => combinedData[header][i]).join(','));
        }

        // Download the CSV
        const blob = new Blob([rows.join('\n')], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${dataID}_data.csv`;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * Handles the mouse moving over the container that holds the 
     * time series charts. If the vertical line is visible and not
     * frozen, it updates the position of the vertical line based 
     * on the mouse position.
     * 
     * @param {MouseEvent} e - The mouse move event
     */
    const handleMouseMove = (e) => {
        if (isLineFrozen.value || !showVerticalLine.value) return;

        const containerRect = chartContainer.value.getBoundingClientRect();
        const leftLimit = leftBoundary;
        const rightLimit = containerRect.width - rightBoundary;

        // Calculate position relative to the container
        const relativeX = e.clientX - containerRect.left;

        updateLinePosition(relativeX, leftLimit, rightLimit, containerRect.height);
    };

    /**
     * Handles the mouse leaving the container that holds the 
     * time series charts. If the vertical line is not frozen, 
     * it is hidden.
     */
    const handleMouseLeave = () => {
        if (!isLineFrozen.value && verticalLine.value) {
            verticalLine.value.style.visibility = 'hidden';
            verticalLine.value.style.opacity = '0';
        }
    };

    /**
     * Handles mouse click events on the container that holds the 
     * time series charts. When clicked within valid boundaries, 
     * the vertical line is fixed to the current position.
     * 
     * @param {MouseEvent} e - The mouse click event
     */
    const handleClick = (e) => {
        if (!showVerticalLine.value) return;

        const containerRect = chartContainer.value.getBoundingClientRect();
        const leftLimit = leftBoundary;
        const rightLimit = containerRect.width - rightBoundary;
        const relativeX = e.clientX - containerRect.left;

        // Only toggle frozen state if click is within the valid range
        if (relativeX >= leftLimit && relativeX <= rightLimit) {
            isLineFrozen.value = !isLineFrozen.value;
            
            if (isLineFrozen.value) {
                // Freeze the line at the current position
                frozenPosition.value = relativeX;
                updateLinePosition(relativeX, leftLimit, rightLimit, containerRect.height);
            } else {
                // Unfreeze the line
                frozenPosition.value = null;
            }
        }
    };

    /**
     * Updates the position of the vertical line based on the 
     * mouse position.
     * 
     * @param {number} x - The x position of the mouse
     * @param {number} leftLimit - The left boundary of the container
     * @param {number} rightLimit - The right boundary of the container
     * @param {number} height - The height of the container
     */
    const updateLinePosition = (x, leftLimit, rightLimit, height) => {
        if (!verticalLine.value) return;

        if (x >= leftLimit && x <= rightLimit) {
            verticalLine.value.style.visibility = 'visible';
            verticalLine.value.style.opacity = '1';
            verticalLine.value.style.left = `${x}px`;
            verticalLine.value.style.height = `${height}px`;
        } else {
            verticalLine.value.style.visibility = 'hidden';
            verticalLine.value.style.opacity = '0';
        }
    };

    /**
     * Calculated subcollections do not automatically update. This function
     * is used to manually update the calculation for a given subject.
     * 
     * @param {string} name - The name of the subcollection to update
     */
    const updateCalculation = async (name) => {
        await Calculation.put('', {
            dataID: dataID,
            subject: name
        });
    }

    // Watch functions
    watch(() => fullTemplate.value, async (newTemplate) => {
        try {
            if (newTemplate?.timeSeries?.charts) {
                timeseriesTemplates.value = newTemplate.timeSeries.charts;
                console.log("timeseriesTemplates", timeseriesTemplates.value);

                // Clear existing data
                data.value = {};

                // Set up data structure
                for (const chart of timeseriesTemplates.value) {
                    for (const subcollection in chart.specifications) {
                        if (!Object.hasOwn(data.value, subcollection)) {
                            data.value[subcollection] = {'x': []};
                        }
                    }
                }

                await setupWorkers();

                // Add a small delay to ensure workers are ready
                await new Promise(resolve => setTimeout(resolve, 100));

                if (isLive.value) {
                    await startStream();
                }
            }
        } catch (error) {
            console.error('Error in template watch:', error);
        }
    }, { deep: true, immediate: true });
</script>

<template>
    <DefaultLayout
    :dataID="dataID"
    >
        <div class="flex items-center justify-between">
            <button @click="handleModal('open'); modal = 'UpdateTemplate';" class="default-button m-5">+ Add Chart</button>
            <div class="flex items-center justify-between mr-3">
                <div>
                    <span class="mr-3">Vertical Line</span>
                    <label class="relative inline-block w-[60px] h-[34px]">
                        <input class="opacity-0 w-0 h-0 peer" type="checkbox" v-model="showVerticalLine">
                        <span class="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 duration-200 before:absolute before:content-[''] before:h-[26px] before:w-[26px] before:left-[4px] before:bottom-[4px] before:bg-white before:duration-200 rounded-[34px] before:rounded-[50%] peer-checked:before:transform peer-checked:bg-blue-500 peer-checked:before:translate-x-[26px]"></span>
                    </label>
                </div>
                <button id="downloadButton" class="default-button ml-5" @click="onRequestDownload">
                    <ArrowDownTrayIcon class="w-5 h-5" />
                </button>
            </div>
        </div>
        <div class="flex">
            <DatePicker v-model="historicalRange" class="mb-5 w-[450px]" />
            <button class="default-button h-[40px] ml-5" @click="onHandleHistorcialData">Apply</button>
            <button v-if="!isLive" class="default-button h-[40px] ml-5" @click="startStream('live')">Back to Live</button>
        </div>
        <div class="relative w-[calc(100%-30px)]" ref="chartContainer">
            <div class="flex flex-col flex-grow">
                <TimeSeries v-for="(template, index) of timeseriesTemplates" 
                    :key="`timeseries-${index}`"
                    :dataID="dataID"
                    :template="template"
                    :data="data"
                    :timeRange="timeRange"
                    :sliderRange="sliderRange"
                    @delete="onDeleteTimeSeries(index)"
                    @modal:state="handleModal"
                    @update="onUpdateTimeseries(index, $event)"
                    @relayout="handleRelayout"
                ></TimeSeries>
            </div>
            <!-- Timeout overlay -->
            <div v-if="timedOut" 
                class="absolute inset-0 flex items-center justify-center z-[9999]"
            >
                <div class="absolute inset-0 bg-gray-900/50"></div>
                <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg text-center">
                    <h3 class="text-lg font-semibold mb-2">Session Timed Out</h3>
                    <p class="mb-4">The live data stream has ended.</p>
                    <button class="default-button" @click="startStream('live')">
                        Restart Stream
                    </button>
                </div>
            </div>
            <!-- Move the vertical line outside the flex container and use visibility instead of display -->
            <div 
                ref="verticalLine" 
                class="absolute h-full w-[3px] bg-black pointer-events-none z-[9999] top-0"
                :style="{ 
                    visibility: showVerticalLine ? 'visible' : 'hidden',
                    opacity: showVerticalLine ? 1 : 0
                }"
            ></div>
        </div>
        <button @click="handleModal('open'); modal = 'UpdateTemplate';" class="default-button m-5">+ Add Chart</button>

        <!-- Use when wanting to add a new chart to the list -->
        <UpdateTemplate v-if="modal === 'UpdateTemplate'"
            :dataID="dataID"
            type="charts"
            @close="handleModal('closed')"
            @details="onAddTimeseries"
        ></UpdateTemplate>
    </DefaultLayout>
</template>