<!-- eslint-disable no-undef -->
<script setup>
    // Imports
    import { ref, watch } from 'vue';

    // Definitions
    const props = defineProps({
        title: {
            type: String,
            default: ""
        },
        jsonValue: {
            type: [Object],
            required: false,
            default: () => ({})
        },
        advanced: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(['update:jsonValue']);

    // References
    const editableValue = ref(props.jsonValue);
    const editableValueString = ref(
        Object.keys(props.jsonValue).length ? JSON.stringify(props.jsonValue, null, 2) : "{\n\n}");
    const showAdvancedEditor = ref(Object.keys(props.jsonValue).length ? false : true);
    const validJson = ref(true);

    // Lifecycle hooks
    watch(() => props.jsonValue, (newValue) => {
        editableValue.value = newValue;
    }, { deep: true });

    watch(editableValue, (newValue) => {
        emit('update:jsonValue', newValue);
    }, { deep: true });

    // Methods
    const formatKey = (key) => {
        if (key === "ssid") {
            return "Network Name";
        }
        if (key === "psk") {
            return "Password";
        }
        return key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    };

    const updateValue = (value, key) => {
        try {
            let target = editableValue.value;
            const keys = key.split('.');
            const lastKey = keys.pop();

            for (const key of keys) {
                if (!(key in target)) {
                    console.log('Key not found:', key);
                    return;
                }
                target = target[key];
            }

            // Type validation
            const originalType = typeof target[lastKey];
            const newValue = originalType === 'number' ? Number(value) : value;
            
            target[lastKey] = newValue;
        } catch (error) {
            console.error('Error updating value:', error);
        }
    };

    const handleAdvancedInput = (event) => {
        try {
            editableValue.value = JSON.parse(event.target.value);
            editableValueString.value = JSON.stringify(editableValue.value, null, 2);
            validJson.value = true;
        } catch (error) {
            editableValueString.value = event.target.value;
            validJson.value = false;
        }
    }
</script>

<template>
    <div>
        <!-- Mode Toggle -->
        <div v-if="advanced && Object.keys(jsonValue).length" class="flex items-center gap-2 mb-4 justify-between">
            <h1 class="">{{ title }}</h1>
            <label class="relative inline-flex items-center cursor-pointer">
                <input 
                    type="checkbox" 
                    v-model="showAdvancedEditor" 
                    class="sr-only peer"
                >
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                          peer-focus:ring-blue-300 rounded-full peer 
                          peer-checked:after:translate-x-full peer-checked:after:border-white 
                          after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                          after:bg-white after:border-gray-300 after:border after:rounded-full 
                          after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
                </div>
                <span class="ml-3 text-sm font-medium text-gray-900">Advanced Editor</span>
            </label>
        </div>

        <!-- Simple Editor -->
        <div v-if="!showAdvancedEditor" class="p-4 border-2 border-color-e-darker rounded-lg min-h-[200px] max-h-[400px] overflow-y-auto">
            <template v-for="(value, key, index) in editableValue" :key="index">
                <div class="json-field" :style="{ marginLeft: '20px' }">
                    <span class="font-bold">{{ formatKey(key) }}: </span>
                    <template v-if="typeof value === 'object' && value !== null">
                        <!-- Nested objects -->
                        <div v-for="(nestedValue, nestedKey, nestedIndex) in value" 
                             :key="nestedIndex" 
                             class="ml-10 mt-2">
                            <template v-if="typeof nestedValue === 'object' && nestedValue !== null">
                                <div class="font-semibold">{{ formatKey(nestedKey) }}:</div>
                                <div v-for="(deepValue, deepKey, deepIndex) in nestedValue"
                                     :key="deepIndex"
                                     class="ml-10 mt-2">
                                    <template v-if="typeof deepValue === 'object' && deepValue !== null">
                                        <div class="font-semibold">{{ formatKey(nestedKey) }}:</div>
                                        <template v-if="Array.isArray(deepValue)">
                                            <!-- Handle Arrays -->
                                            <div v-for="(arrayItem, arrayIndex) in deepValue"
                                                 :key="arrayIndex"
                                                 class="ml-10 mt-2">
                                                <div class="flex items-center gap-4 min-w-0">
                                                    <div class="w-[50px] shrink-0">
                                                        <span class="truncate block">[{{ arrayIndex }}]: </span>
                                                    </div>
                                                    <input 
                                                        :value="arrayItem"
                                                        @input="updateValue($event.target.value, `${key}.${nestedKey}.${arrayIndex}`)"
                                                        class="border rounded px-2 py-1 min-w-[200px]"
                                                        :placeholder="typeof arrayItem"
                                                        :readonly="readonly"
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <!-- Handle Objects -->
                                            <div v-for="(objValue, objKey, objIndex) in deepValue"
                                                 :key="objIndex"
                                                 class="ml-10 mt-2">
                                                <div class="flex items-center gap-4 min-w-0">
                                                    <div class="w-[200px] shrink-0">
                                                        <span class="truncate block">{{ formatKey(objKey) }}: </span>
                                                    </div>
                                                    <input 
                                                        v-if="typeof objValue !== 'object'"
                                                        :value="objValue"
                                                        @input="updateValue($event.target.value, `${key}.${nestedKey}.${objKey}`)"
                                                        class="border rounded px-2 py-1 flex-1 min-w-[200px]"
                                                        :placeholder="typeof objValue"
                                                        :readonly="readonly"
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="flex items-center gap-4 min-w-0">
                                            <div class="w-[200px] shrink-0">
                                                <span class="truncate block">{{ formatKey(deepKey) }}: </span>
                                            </div>
                                            <input 
                                                :value="deepValue"
                                                @input="updateValue($event.target.value, `${key}.${nestedKey}.${deepKey}`)"
                                                class="border rounded px-2 py-1 flex-1 min-w-[200px]"
                                                :placeholder="typeof deepValue"
                                                :readonly="readonly"
                                            >
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="grid grid-cols-[120px_1fr] items-center gap-2">
                                    <span class="whitespace-nowrap">{{ formatKey(nestedKey) }}: </span>
                                    <input 
                                        :value="nestedValue"
                                        @input="updateValue($event.target.value, `${key}.${nestedKey}`)"
                                        class="border rounded px-2 py-1 w-48"
                                        :placeholder="typeof nestedValue"
                                        :readonly="readonly"
                                    >
                                </div>
                            </template>
                        </div>
                    </template>
                    <input 
                        v-else
                        :value="value"
                        @input="updateValue($event.target.value, key)"
                        class="border rounded px-2 py-1 w-48"
                        :placeholder="typeof value"
                        :readonly="readonly"
                    >
                </div>
            </template>
        </div>

        <!-- Advanced Editor -->
        <div v-else class="bg-color-e-darker p-4 rounded-lg">
            <textarea
                v-model="editableValueString"
                @input="handleAdvancedInput"
                class="w-full h-[400px] font-mono text-sm p-2 border rounded"
                :readonly="readonly"
                placeholder="Enter JSON..."
            ></textarea>
        </div>
        <div v-if="!validJson" class="text-red-500 text-sm mt-2">Invalid JSON format</div>
    </div>
</template>