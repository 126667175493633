import { auth } from '@/firebase';
import { db } from '@/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/device';

class Device {
    static async get(path='', token=null) {
        // Handle streaming requests
        if (path.includes('stream')) {
            // Extract dataID from the path
            const dataID = path.split('dataID=')[1];
            if (!dataID) {
                throw new Error('DataID is required for streaming');
            }

            // Return a promise that resolves with both the unsubscribe function
            // and the initial data
            return new Promise((resolve, reject) => {
                const unsubscribe = onSnapshot(
                    doc(db, 'data', dataID),
                    (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            // If this is the first update, resolve the promise
                            if (!unsubscribe.initialized) {
                                unsubscribe.initialized = true;
                                resolve({
                                    details: docSnapshot.data(),
                                    unsubscribe: unsubscribe
                                });
                            }
                            // Call the callback if provided
                            if (unsubscribe.callback) {
                                unsubscribe.callback({
                                    details: docSnapshot.data()
                                });
                            }
                        } else {
                            const error = new Error('Device not found');
                            if (!unsubscribe.initialized) {
                                reject(error);
                            } else if (unsubscribe.callback) {
                                unsubscribe.callback({ error });
                            }
                        }
                    },
                    (error) => {
                        if (!unsubscribe.initialized) {
                            reject(error);
                        } else if (unsubscribe.callback) {
                            unsubscribe.callback({ error });
                        }
                    }
                );

                // Add a method to set the callback for future updates
                unsubscribe.setCallback = (callback) => {
                    unsubscribe.callback = callback;
                };
            });
        }
        else {
            // Handle regular REST API requests
            const response = await fetch(`${URL}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
            },
            });

            return response.json();
        }
    }

    static async post(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: body,
        });

        return response.json();
    }

    static async put(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
            },
            body: body,
        });
        return response.json();
    }

    static async delete(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: body,
        });
        return response.json();
    }
}

export default Device;