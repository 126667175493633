<script setup>
    // Imports
    import { useStore } from 'vuex';
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';

    import DefaultLayout from '@/layouts/Layout.vue';
    import EventsTable from '@/components/tables/EventsTable.vue';
    import CreateEvent from '@/components/modals/CreateEvent.vue';
    import Template from '@/api/template';
    import Event from '@/api/event';

    // References
    const store = useStore();
    const createEvent = ref(false);
    const fullTemplate = ref(store.state.template);

    // Set up all other variables
    const user = store.state.user;
    const uid = user.uid;
    const route = useRoute();
    const dataID = route.params.dataID;

    // Lifecycle hooks
    onMounted(async () => {
    });


    // Methods
    const updateTemplate = async (newTemplate) => {
        // Grab the template ID and the template itself
        const { id, ...template } = newTemplate;
        
        const body = {
            templateID: id,
            template: template,
        }

        console.log("update template body: ", body);
        // Send the request to the API to update the template
        const response = await Template.put('', body);
        console.log("update template response: ", response);

        if (response.status !== 'success') {
            alert("Failed to update the template.");
            return false;
        }

        return true;
    }

    const createEventTable = async (tableData) => {
        console.log(tableData);

        // Create a new event in the database and generate occurrences.
        const body = {
            dataID: dataID,
            name: tableData.source,
            comparison: tableData.comparison,
            startDate: tableData.startDate,
            values: tableData.values,
        };

        try{
            const response = await Event.post('', body);
            console.log(response);

            if (response.status !== 'success') {
                alert("Failed to create the event.");
                return;
            }
        } catch (error) {
            console.log(error);
            return;
        }

        // Update the template with the new event chart.
        const newTemplate = {...fullTemplate.value};
        newTemplate.events.tables.push({
            title: tableData.title,
            columns: tableData.columns,
            type: 'event',
        })

        // Update the template in the database.
        if (await updateTemplate(newTemplate)) {
            // Mirror the change here if the update was successful.
            fullTemplate.value = newTemplate;
            store.dispatch('setTemplate', fullTemplate.value);
        } else {
            alert("Failed to create the event.");
        }

        createEvent.value = false;
    };

    const updateColumns = async (index, columns) => {
        if (fullTemplate.value.events.tables[index].columns !== columns) {
            const newTemplate = {...fullTemplate.value};
            newTemplate.events.tables[index].columns = columns;

            const body = {
                templateID: fullTemplate.value.id,
                template: newTemplate,
            }

            const response = await Template.put('', body);
            
            if (response.status !== 'success') {
                alert("Failed to update the template.");
                return;
            }

            fullTemplate.value = newTemplate;
            store.dispatch('setTemplate', fullTemplate.value);
        }
    }

    const deleteTable = async (index) => {
        // Delete the event from the database.
        const response = await Event.delete('', {
            dataID: dataID,
            name: fullTemplate.value.events.tables[index].title.replace(/\s+/g, '_'),
        });
        console.log("Delete event Response");
        console.log(response);
        if (response.status !== 'success' && response.status !== 'partial_success') {
            alert("Failed to delete the table.");
            return;
        }
        console.log("Delete event success");
        // Create an instance of the template and edit to reflect the change.
        const newTemplate = {...fullTemplate.value};
        newTemplate.events.tables.splice(index, 1);
        console.log("creating new template: ", newTemplate);
        // Update the template in the database.
        if (await updateTemplate(newTemplate)) {
            // Mirror the change here if the update was successful.
            fullTemplate.value = newTemplate;
            store.dispatch('setTemplate', fullTemplate.value);
        } else {
            alert("Failed to delete the table.");
        }
    };
</script>

<template>
    <DefaultLayout
    :dataID="dataID"
    >
        <!-- <button @click="createEvent = true">+ New Table</button> -->
        <div class="flex flex-wrap flex-row justify-around">
            <div v-for="(table, index) in fullTemplate?.events.tables" :key="index" style="width: 99%; margin-top: 20px; margin-bottom: 40px;">
                <EventsTable v-if="table.type === 'event'"
                    :dataID="dataID"
                    :template="table"
                    @columnUpdate="updateColumns(index, $event)"
                    @delete="deleteTable(index)"
                ></EventsTable>
            </div>
        </div>
        <button @click="createEvent = true" class="default-button m-5">+ Add Event Table</button>
        <CreateEvent v-if="createEvent"
            :dataID="dataID"
            :uid="uid"
            @close="createEvent = false"
            @eventDetails="createEventTable"
            >
        </CreateEvent>
    </DefaultLayout>
</template>