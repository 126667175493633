<template>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="flex items-center">
          <span v-if="company === 'All'" class="mx-5 text-4xl">{{ company }}</span>
          <span v-else class="hidden lg:block mx-5 text-4xl">{{ company }}</span>
          <ChevronDownIcon class="h-10 w-10" aria-hidden="true" />
        </MenuButton>
      </div>
  
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="hidden lg:block absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" :class="company === 'All' ? 'left-12' : 'right-0'">
          <div class="py-1">
            <MenuItem v-for="(value, id) of entities" :key="id" v-slot="{ active }">
              <a href="#" @click="setEntity(id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ value.name }}</a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a href="#" @click="setEntity('All')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">All</a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>

      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="lg:hidden absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" :class="company === 'All' ? 'left-16' : 'left-0'">
          <div class="py-1">
            <MenuItem v-for="(value, id) of entities" :key="id" v-slot="{ active }">
              <a href="#" @click="setEntity(id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ value.name }}</a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a href="#" @click="setEntity('All')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">All</a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
</template>

<script>
import { useStore } from 'vuex';
import { router } from '@/router/index';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
    name: "SelectEntity",
    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronDownIcon
    },
    props: {
        company: String,
        entities: Object,
    },
    setup(props) {

      const store = useStore();
      const user = store.state.user;
      
      const setEntity = (entityId) => {
          if (entityId === 'All') {
          user.entityViewing = [];
          for ( let key in props.entities) {
              user.entityViewing.push(key);
          }
          } else {
          user.entityViewing = [entityId];
          }

          store.dispatch('setUser', user);
          if (window.location.pathname === '/dashboard') {
          window.location.reload();
          } else if (window.location.pathname === '/sim-cards') {
          window.location.reload();
          } else {
          router.push({ name: 'Dashboard' });
          }
      };

      return {
          setEntity,
      };
    },
};
</script>