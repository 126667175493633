<script setup>
    // Imports
    import { useStore } from 'vuex';
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { doc, getDoc } from 'firebase/firestore';
    import { db } from '@/firebase';

    import DefaultLayout from '@/layouts/Layout.vue';
    import GenericGauge from '@/components/charts/Guage.vue';
    import StatsCard from '@/components/cards/StatsCard.vue';
    import GenericTable from '@/components/tables/GenericTable.vue';
    import EventLog from '@/components/tables/EventLog.vue';
    import UnitMap from '@/components/maps/UnitMap.vue';

    // References
    const pageTitle = ref('');
    const pageSubtitle = ref('');
    const subpageLinks = ref(new Map());
    const statistics = ref(new Object());
    const gauges = ref(new Object());
    const tables = ref(new Object());
    const infoCards = ref(new Object());
    const maps = ref(new Object());

    // Set up all other variables
    const route = useRoute();
    const store = useStore();
    const timeFormat = store.state.user.timeFormat;
    const dataID = route.params.dataID;
    let defaultTemplate;

    // Lifecycle hooks
    onMounted(async () => {
        await getUnitDetails();
        getTemplate();
    });

    // Methods
    const getUnitDetails = async () => {
        // Attempt the data document using the data ID
        try {
            const docRef = doc(db, "data", dataID);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const unitDetails = docSnap.data();
                defaultTemplate = unitDetails.defaultTemplate;
            }
            
        } catch(error) {
            console.log(error)
            pageTitle.value = dataID;
            pageSubtitle.value = "Failed to retrieve data";
        }
    }

    const getTemplate = async () => {
        // The template is not stored so grab it from the database.
        try {
            const docRef = doc(db, "templates", defaultTemplate);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // Grab the document data and store it
                const templateData = docSnap.data();

                // Grab the statistics.
                statistics.value = templateData.overview.statistics || [];
                tables.value = templateData.overview.tables;
                gauges.value = templateData.overview.gauges;
                infoCards.value = templateData.overview.infoCards;
                maps.value = templateData.overview.maps;

                // Add any other additional subpage links
                subpageLinks.value.set('Overview', [null, 'inactive']);

                if (templateData.timeSeries) {
                    subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/time-series`]);
                }

                if (templateData.events) {
                    subpageLinks.value.set('Events', [`/dashboard/${dataID}/events`]);
                }

                subpageLinks.value.set('Management', [`/dashboard/${dataID}/management`]);
            }

        } catch(error) {
            console.log(error);
        }            
    }

</script>

<template>
    <DefaultLayout
    :dataID="dataID"
    >
    <div class="flex flex-wrap h-full">
        <div class="flex w-full">
            <div class="flex max-w-[700px] justify-around flex-wrap mb-[20px]">

                <!-- Add gauges -->
                <GenericGauge v-for="(gauge, index) in gauges" :key="index"
                    :dataID="dataID"
                    :template="gauge"
                    :timeFormat="timeFormat"
                ></GenericGauge>

                <!-- Add statistics cards -->
                <StatsCard v-for="(statDetails, index) in infoCards" :key="index" class="min-w-[325px]"
                    :statDetails="statDetails"
                    :dataID="dataID"
                    :timeFormat="timeFormat"
                ></StatsCard>
            </div>

            <!-- Add historical map -->
            <div class="flex-1 mr-5">
                <UnitMap v-for="(mapDetails, index) in maps" :key="index"
                    :dataID="dataID"
                    :template="mapDetails"
                ></UnitMap>
            </div>
        </div>

        <!-- Add generic tables -->
        <div v-for="(table, index) in tables" :key="index" class="my-[10px] w-[99%]">
            <GenericTable v-if="table.type === 'generic'"
                :dataID="dataID"
                :template="table"
                :timeFormat="timeFormat"
            ></GenericTable>
        </div>

        <!-- Add more statistics cards -->
        <StatsCard v-for="(statDetails, index) in statistics" :key="index" class="min-w-[325px]"
            :statDetails="statDetails"
            :dataID="dataID"
            :timeFormat="timeFormat"
        ></StatsCard>

        <!-- Add event log -->
        <div class=" flex-grow mr-5" :class="statistics.length ? 'flex-grow' : 'flex-grow'">
            <EventLog style="margin-bottom: 15px;"
                :dataID="dataID"
                :timeFormat="timeFormat"
            ></EventLog>
        </div>
    </div>
  
    </DefaultLayout>
</template>