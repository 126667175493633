<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { ref, onMounted } from 'vue';
  import { TrashIcon } from '@heroicons/vue/24/solid';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
  // import SimpleDropdown from '../dropdowns/SimpleDropdown.vue';
  import Data from '@/api/data';

  // Definitions
  const props = defineProps({
    dataID: {
      type: String,
      default: null
    },
    subcollectionOptions: {
      type: Object,
      default: () => ({})
    }
  });

  const emit = defineEmits(['event-details', 'close']);

  const loading = ref(false);
  const subcollections = ref(props.subcollectionOptions);
  const name = ref('');
  const source = ref('');
  const operation = ref('');
  const constant = ref('');
  const startFrom = ref('');
  const variables = ref([{source: '', field: ''}]);
  const error = ref('');

  // Lifecycle hooks
  onMounted(async() => {
    if (Object.keys(subcollections.value).length === 0) {
      await getSubcollections();
    }
  });

  // Methods

  // Get the subcollections and their fields connected to the dataID.
  const getSubcollections = async() => {
    const response = await Data.get(`/options?dataID=${props.dataID}`);
    if (response.status === 'success') {
      // Ignore subcollections that are not relevant to the timeseries.
      const ignoreSubcollections = ['Net_Cfg', 'action_request', 'action_result', 'location', 'messages']
      subcollections.value = Object.fromEntries(
        Object.entries(response.details).filter(([key]) => !ignoreSubcollections.includes(key))
      );
    }
  }

  const validateOperation = () => {
    loading.value = true;
    // Check for any invalid source patterns.
    const sourcePattern = /([a-zA-Z_][a-zA-Z0-9_]*)\.[a-zA-Z_][a-zA-Z0-9_]*/g;
    if (!sourcePattern.test(source.value)) {
      error.value = 'Invalid variable reference';
      return false;
    }

    // Check is source contains a valid subcollection and field.
    const [subcollection, field] = source.value.split('.');
    if (!Object.keys(subcollections.value).includes(subcollection)) {
      error.value = 'Invalid operation value reference';
      return false;
    }
    if (!Object.keys(subcollections.value[subcollection]).includes(field)) {
      error.value = 'Invalid operation value reference';
      return false;
    }

    // Check if the constant is a valid number.
    if (constant.value === '' || isNaN(Number(constant.value))) {
      error.value = 'Invalid constant - must be a valid number';
      return false;
    }

    // Everything checks out.
    return true;
  }

  const handleSubmit = async() => {
    loading.value = true;
    error.value = '';

    // Replace spaces with underscores in the name.
    const formattedName = name.value.replace(/\s+/g, '_');

    // Check if the operation is valid.
    if (!validateOperation()) {
      loading.value = false;
      return;
    }

    // Build the operation.
    const comparisonOperation = `${source.value} ${operation.value} ${constant.value}`;

    const eventDetails = {
      columns: ["startUTC", "endUTC", "duration", "tag"],
      source: formattedName,
      title: name.value,
      comparison: comparisonOperation,
      startDate: startFrom.value,
      values: variables.value,
    }

    // Emit the new calculation details and close the modal.
    emit('event-details', eventDetails);
  }

  const close = () => {
    emit('close');
  };
</script>

<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="close">
    <div class=" bg-color-f p-[20px] rounded-lg w-[600px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class=" font-extrabold text-xl underline text-center">Create A New Event</h2>
      <p class="font-normal text-center text-orange-600">WARNING: This will affect all devices that share the same template.</p>
      <br>
      <form @submit.prevent="handleSubmit">

        <!-- Collection name -->
        <label class="font-bold">
          Name:
          <br>
          <input v-model="name" type="text" class="w-full font-normal" required />
        </label>

        <!-- Variables -->
        <div v-for="(value, index) in variables" :key="index" class="mt-5 flex gap-2">
          <div v-if="variables.length > 1" class="flex gap-2 items-center hover:cursor-pointer pr-2 pt-5" @click="variables.splice(index, 1)">
            <TrashIcon class="h-[25px] w-[25px]"/>
          </div>

          <!-- Source -->
          <label class="font-bold">
              Source:
            <select :value="value.source" style="width: 100%;" @change="variables[index].source = $event.target.value" class="font-normal" required>
              <option v-for="subcollection in Object.keys(subcollections)" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
            </select>
          </label>

          <!-- field -->
          <label class="font-bold w-[175px] mr-3">
            Value: <span v-if="!value.source" class="font-normal text-red-600">(select source)</span>
            <select :value="value.field || ''" @change="variables[index].field = $event.target.value" :disabled="!value.source" style="width: 100%;" class="font-normal" required>
              <option v-for="field in Object.keys(subcollections[value.source] ?? []).filter(key => 
                subcollections[value.source][key] === 'int' || 
                subcollections[value.source][key] === 'float')" 
                :key="field" 
                :value="field">
                {{ field }}
              </option>
            </select>
          </label>

          <!-- Add to operation -->
          <button type="button" 
            v-if="value.source && value.field" 
            @click="source = `${value.source}.${value.field}`" 
            class="default-button w-[70px] h-[40px] text-center p-0 mt-6">
            Insert in operation
          </button>
        </div>

        <!-- Add another variable -->
        <button type="button" @click="variables.push({source: '', field: ''})" class="text-color-a font-bold" :class="{'ml-10': variables.length > 1}">+ Add another value</button>

        <!-- Comparison operation -->
        <div class="mt-5 flex gap-2">
          
          <label class="font-bold flex-grow">
            Value:
            <span v-if="variables[0].field" class="font-normal ml-2">e.g. = source.value</span>
            <input
              ref="equationDOM"
              v-model="source" 
              type="text" 
              class="w-full font-normal" 
              required 
            />
          </label>

          <div class="flex-none">
            <label class="font-bold block">
              Operation:
              <br>
              <select v-model="operation" @change="operation = $event.target.value" class="font-normal" required>
                <option value=">">&gt;</option>
                <option value=">=">&gt;=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value="==">=</option>
                <option value="!=">!=</option>
              </select>
            </label>
          </div>

          <div class="flex-none">
            <label class="font-bold block">
              Constant:
              <br>
              <input
                ref="equationDOM"
                v-model="constant" 
                type="text" 
                class="w-[100px] font-normal" 
                required 
              />
            </label>
          </div>
        </div>

        <!-- Start From -->
        <div class="mt-5">
          <label class="font-bold">
            Start From:
            <br>
            <input v-model="startFrom" type="date" class="w-full font-normal" required />
          </label>
        </div>

        <!-- Error -->
        <div v-if="error" class="mt-5">
          <p class="font-normal ml-2 text-red-600 whitespace-pre-line">* {{ error }}</p>
        </div>

        <!-- Save and close -->
        <div class="flex justify-between mt-10">
          <button type="button" @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" class="default-button" :class="{'default-button-off': loading}">Create</button>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>
