<!-- eslint-disable no-undef -->
<script setup>
  // Imports
  import { ref, onMounted } from 'vue';
  import { TrashIcon } from '@heroicons/vue/24/solid';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
  // import SimpleDropdown from '../dropdowns/SimpleDropdown.vue';
  import Data from '@/api/data';
  import SetupCalculation from './SetupCalculation.vue';

  // Definitions
  const props = defineProps({
    dataID: {
      type: String,
      default: null
    },
    template: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: null
    }
  });

  const emit = defineEmits(['details', 'close', 'error']);

  const createNewSource = ref(false);
  const loading = ref(false);
  const subcollections = ref({});
  const details = ref([]);
  const errors = ref([]);
  const typeValue = ref(props.type);
  const titleValue = ref(props.template.title);


  // Lifecycle hooks
  onMounted(async() => {
    await getSubcollections();
    parseTemplate();
  });

  // Methods

  // Get the subcollections and their fields connected to the dataID.
  const getSubcollections = async() => {
    const response = await Data.get(`/options?dataID=${props.dataID}`);
    if (response.status === 'success') {
      // Ignore subcollections that are not relevant to the timeseries.
      const ignoreSubcollections = ['Net_Cfg', 'action_request', 'action_result', 'location', 'messages']
      subcollections.value = Object.fromEntries(
        Object.entries(response.details).filter(([key]) => !ignoreSubcollections.includes(key))
      );
    }
  }

  // If there is a template, fill in details with template details.
  const parseTemplate = () => {
    if (props.template) {
      for (const key in props.template.specifications) {
        const specifications = props.template.specifications[key];
        for (const detail of specifications) {
          details.value.push({
            source: key,
            field: detail.field || '',
            friendlyName: detail.friendlyName || '',
            unit: detail.unit || '',
            color: detail.color || '#FF8800',
          });
        }
      }
    }
  }

  const addDetails = () => {
    details.value.push({
      source: '',
      field: '',
      friendlyName: '',
      unit: '',
      color: '#FF8800',
    });
  }

  const updateDetails = async (index, key, value) => {
    // Update the option key to the value at index.
    details.value[index][key] = value;

    // if the source is changed, see if we can pre-fill the field and unit values.
    if (key === 'source') {

      if (value === 'custom') {
        createNewSource.value = true;
        return;
      }
      
      // If the source has a unit field, grab its value.
      if ("unit" in subcollections.value[value]) {
      
        const body = {
          dataID: props.dataID,
          subject: value,
          limit: 1
        }

        const response = await Data.post(`/options?dataID=${props.dataID}`, body);
        if (response.status === 'success') {
          details.value[index].unit = response.details[0].unit;
        } else {
          details.value[index].unit = '';
        }
      } else {
        details.value[index].unit = '';
      }

      // If the source only has one valid numeric field, pre-fill the field value
      const validFields = Object.entries(subcollections.value[value])
        .filter(([, type]) => type === 'int' || type === 'float')
        .map(([field]) => field);

      if (validFields.length === 1) {
        details.value[index].field = validFields[0];
        details.value[index].friendlyName = value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      } else {
        details.value[index].field = '';
        details.value[index].friendlyName = '';
      }
    }
  }

  const removeDetails = (index) => {
    details.value.splice(index, 1);
  }

  const handleSubmit = () => {
    emit('details', {type: typeValue.value, title: titleValue.value, specifications: details.value});
    close();
  }

  const close = () => {
    emit('close');
  };
</script>

<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="close">
    <div class=" bg-color-f p-[20px] rounded-lg max-w-[400px] max-h-[calc(100vh-50px)] overflow-y-auto">
      <h2 class=" font-extrabold text-xl underline">Widget Settings</h2>
      <br>
      <form @submit.prevent="handleSubmit">

        <!-- Widget Type -->
        <label class="font-bold w-[75px] mr-3">
            Widget Type:
            <br>
            <select v-model="typeValue" style="width: 100%;" required :disabled="type">
              <option value="guages">Guage</option>
              <option value="table">Table</option>
              <option value="charts">Chart</option>
              <option value="maps">Map</option>
              <option value="infoCards">List</option>
            </select>
        </label>
        
        <br><br>

        <!-- Title -->
        <label class="font-bold">
          Title:
          <br>
          <input v-model="titleValue" type="text" required />
        </label>

        <br><br>

        <!-- Inputs for Charts -->
        <div v-if="typeValue === 'charts'">
          <div v-for="(value, index) in details" :key="index" class="border-t-4 border-black pt-3 mb-3">
            <div v-if="details.length > 1" class="flex gap-2 items-center hover:cursor-pointer text-red-500" @click="removeDetails(index)">
              <TrashIcon class="h-4 w-4"/>
              <p class="font-bold">Remove</p>
              <br><br>
            </div>

            <!-- Source -->
            <label class="font-bold">
                Source:
              <select :value="value.source" style="width: 100%;" @change="updateDetails(index, 'source', $event.target.value)" required>
                <option v-for="subcollection in Object.keys(subcollections)" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
                <option value="custom" class=" text-sky-600">+ Custom</option>
              </select>
            </label>

            <br><br>

            <!-- field -->
            <label class="font-bold w-[175px] mr-3">
              Value: <span v-if="!value.source" class="font-normal text-red-600">(select source)</span>
              <select :value="value.field || ''" @change="updateDetails(index, 'field', $event.target.value)" style="width: 100%;" required>
                <option v-for="field in Object.keys(subcollections[value.source] ?? []).filter(key => 
                  subcollections[value.source][key] === 'int' || 
                  subcollections[value.source][key] === 'float')" 
                  :key="field" 
                  :value="field">
                  {{ field }}
                </option>
              </select>
            </label>

            <br><br>

            <!-- friendlyName -->
            <label class="font-bold">
              Friendly Name: <span v-if="errors[index]" class="font-normal text-red-600">{{ errors[index] }}</span>
              <br>
              <input type="text" :value="value.friendlyName || ''" @input="updateDetails(index, 'friendlyName', $event.target.value)" />
            </label>

            <br><br>
            <div class="flex gap-2">

              <!-- unit -->
              <label class="font-bold w-[100px]">
                Unit:
                <br>
                <input type="text" class="w-full" :value="value.unit ?? ''" @input="updateDetails(index, 'unit', $event.target.value)" />
              </label>

              <br><br>

              <!-- color -->
              <label class="font-bold w-[50px]">
                Color:
                <br>
                <input type="color" :value="value.color" @input="updateDetails(index, 'color', $event.target.value)" required class="h-[45px]"/>
              </label>
            </div>

          </div>
        </div>
        <button type="button" @click="addDetails()" class="text-color-a font-bold">+ Add Value</button>

        <!-- Inputs for other widgets -->
        
        <br><br>
        <div class="flex justify-between">
          <button type="button" @click="close" class="default-button">Close</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          <button v-else type="submit" :disabled="loading || errors.length > 0" class="default-button" :class="{'default-button-off': errors.length > 0 || loading}">Save</button>
        </div>
      </form>
    </div>
    <div id="preview">
    </div>
  </div>

  <SetupCalculation v-if="createNewSource"
    :dataID="props.dataID"
    :subcollectionOptions="subcollections"
    @close="createNewSource = false"
    @details="subcollections = $event"
  />
</template>
