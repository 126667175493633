<!-- eslint-disable no-undef -->
<script setup>
    // Imports
    import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue';
    import Plotly from 'plotly.js-dist';
    import { TrashIcon, PencilIcon } from '@heroicons/vue/24/solid';

    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';
    import UpdateTemplate from '@/components/modals/updateTemplate.vue';

    // Props
    const props = defineProps({
        dataID: String,
        template: Object,
        data: Object,
        timeRange: {
            type: Array,
            default: null
        },
        sliderRange: {
            type: Array,
            default: null
        }
    });

    // Emits
    const emits = defineEmits(['update', 'delete', 'relayout', 'modal:state']);

    // References
    const plotLines = ref([]);
    const config = ref({displaylogo: false, responsive: true})
    const loading = ref(true);
    const error = ref(null);
    const modal = ref(null);
    const layout = ref(null);
    // Add a ref to the Plotly component
    const plotlyContainer = ref(null);

    // Methods
    const setTimeSeriesLayout = () => {
        layout.value = {
            showlegend: true,
            legend: {
                x: 0,
                y: -1,
                orientation: 'h',
            },
            title: {
                text: props.template.title,
                font: {
                    size: 25,
                    weight: 'bold',
                },
                xref: 'paper',
                x: 0,
                xanchor: 'left',
                y: 1,
                yanchor: 'top',
                pad: { t: 10, b:10},
            },
            // annotations: [
            //     {
            //         text: 'Broken',
            //         xref: 'paper',
            //         yref: 'paper',
            //         x: 0.0,
            //         y: -1.0,
            //         showarrow: false,
            //     },
            // ],
            xaxis: {
                autorange: props.timeRange ? false : true,
                range: props.timeRange,
                mirror: true,
                ticks: 'outside',
                showline: true,
                type: 'date',
                rangeselector: {
                    buttons: [
                        {
                            count: 24,
                            label: '24hr',
                            step: 'hour',
                            stepmode: 'backward',
                        },
                        {
                            count: 12,
                            label: '12hr',
                            step: 'hour',
                            stepmode: 'backward'
                        },
                        {
                            count: 30,
                            label: '30min',
                            step: 'minute',
                            stepmode: 'backward'
                        },
                        {
                            count: 10,
                            label: '10min',
                            step: 'minute',
                            stepmode: 'backward'
                        },
                    ]
                },
                rangeslider: {
                    visible: true,
                    autorange: props.sliderRange ? false : true,
                    range: props.sliderRange,
                    borderwidth: 1,
                    bordercolor: 'black'
                },
            },
            yaxis: {
                autorange: true,
                mirror: true,
                ticks: 'outside',
                showline: true,
                type: 'linear'
            },
            margin: {
                l: 50,
                r: 50,
                t: 100,
                b: 100,
                pad: 4
            },
            autosize: true,
        }
    };

    const setPlotLineLayout = () => {
        // Reset the plot lines
        plotLines.value = [];

        // Set the plot lines
        for (const subcollection of Object.keys(props.template.specifications)) {
            for (const plotLine of props.template.specifications[subcollection]) {
                plotLines.value.push({
                    type: "scatter",
                    mode: "lines+markers",
                    name: (`<b>${plotLine.friendlyName}</b>
                            <br>retrieving data...`),
                    x: [0],
                    y: ['2000-01-01 00:00:00'],
                    hovertemplate: `DATE: %{x}<br>VALUE: %{y} ${plotLine.unit || ''}<extra></extra>`,
                    line: {
                        color: plotLine.color
                    },
                    marker: {
                        size: 6,
                        symbol: 'circle',
                        maxdisplayed: 50,
                    },

                });
            }
        }
    };

    const setData = async() => {
        for (const subcollection of Object.keys(props.template.specifications)) {
            for (const plotLine of props.template.specifications[subcollection]) {
                const foundPlotLine = plotLines.value.find(line => line.name.includes(plotLine.friendlyName));
                // Update the plot line if there is data
                if (props.data[subcollection]['x'].length > 0) {

                    // Add the data
                    foundPlotLine.x = props.data[subcollection]['x'];
                    foundPlotLine.y = props.data[subcollection][plotLine.field];

                    // Update the name to show the last value
                    const lastValue = foundPlotLine.y[foundPlotLine.y.length - 1];
                    foundPlotLine.name = (`<b>${plotLine.friendlyName}</b>
                                            <br>Last timestamp: ${foundPlotLine.x[foundPlotLine.x.length - 1]}
                                            <br>Last value: ${lastValue} ${plotLine.unit || ''}`);

                    loading.value = false;
                } else {
                    loading.value = true;
                }
            }
        }
        
        // Update the plot with the new data
        if (plotlyContainer.value && !loading.value) {
            Plotly.react(
                plotlyContainer.value,
                plotLines.value,
                layout.value,
                config.value
            );
        }
    };

    const updateTemplate = (updates) => {
        const newTemplate = {
            title: updates.title,
            type: "timeseries",
            specifications: {}
        };

        for (const details of updates.specifications) {
            const { source, ...remainingDetails } = details;
            if (source in newTemplate.specifications) {
                newTemplate.specifications[source].push(remainingDetails);
            } else {
                newTemplate.specifications[source] = [remainingDetails];
            }
        }
        emits('template:update', newTemplate);
    }

    const resizeChart = () => {
        if (plotlyContainer.value) {
            Plotly.Plots.resize(plotlyContainer.value);
        }
    }

    // Lifecycle hooks
    onMounted(() => {
        setTimeSeriesLayout();
        setPlotLineLayout();

        // Initialize the plot after the DOM is ready
        nextTick(() => {
            if (plotlyContainer.value) {
                Plotly.newPlot(
                    plotlyContainer.value,
                    plotLines.value,
                    layout.value,
                    config.value
                );

                // Add an event listener
                plotlyContainer.value.on('plotly_relayout', (event) => emits('relayout', event));
                // plotlyContainer.value.on('plotly_hover', handleLayoutChange);
            }
        });

        // Add resize listener
        window.addEventListener('resize', resizeChart);
    });

    onUnmounted(() => {
        // Remove the resize listener
        window.removeEventListener('resize', resizeChart);

        // Clean up Plotly instance
        if (plotlyContainer.value) {
            Plotly.purge(plotlyContainer.value);
        }
    });

    // Watch functions
    watch(() => props.data, () => {
        setData();
    }, { deep: true });

    watch(() => props.timeRange, (newTimeRange) => {

        layout.value.xaxis.range = newTimeRange;

        // Update the plot with the new layout
        if (plotlyContainer.value) {
            Plotly.relayout(plotlyContainer.value, layout.value);
        }
    }, { deep: true });

    watch(() => props.sliderRange, (newSliderRange) => {
        layout.value.xaxis.rangeslider.range = newSliderRange;

        // Update the plot with the new layout
        if (plotlyContainer.value) {
            Plotly.relayout(plotlyContainer.value, layout.value);
        }
    }, { deep: true });

    watch(() => props.template, () => {
        if (layout.value) {
            layout.value.title.text = props.template.title;
            setPlotLineLayout();
        
            // Update the plot with the new layout
            if (plotlyContainer.value) {
                Plotly.react(
                    plotlyContainer.value,
                    plotLines.value,
                    layout.value,
                    config.value
                );
            }
        }
    }, { deep: true });

</script>

<template>
    <div class="bg-color-f rounded-lg p-5 h-fit mb-5 mr-3 w-full relative">
        <div class="absolute top-2 right-2 z-10 flex">
            <PencilIcon @click="modal = 'edit'; emits('modal:state', 'open')" class="w-5 h-5 mr-5 hover:cursor-pointer"></PencilIcon>
            <TrashIcon @click="emits('delete')" class="w-5 h-5 hover:cursor-pointer"></TrashIcon>
        </div>
        <div v-if="error" class="absolute inset-0 top-[90px] left-[100px] right-[100px] bottom-[100px] bg-white/50 flex items-center justify-center z-20">
            <div class="text-2xl font-bold">{{ error }}</div>
        </div>
        <div v-else-if="loading" class="absolute inset-0 top-[90px] left-[50px] right-[50px] bottom-[100px] bg-white/50 flex items-center justify-center z-20">
            <LoadingAnimation1 class="w-10 h-10"></LoadingAnimation1>
        </div>
        <div class="w-full">
            <!-- Plotly container -->
            <div ref="plotlyContainer" class="w-full h-[500px]"></div>
        </div>
    </div>
    <UpdateTemplate v-if="modal === 'edit'"
        :dataID="dataID"
        :template="template"
        type="charts"
        @close="modal = null; emits('modal:state', 'closed')"
        @details="updateTemplate"
    ></UpdateTemplate>
</template>