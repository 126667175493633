<template>
  <div class="login-background" >
    <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <!-- <img class="mx-auto h-10 w-auto" src="https://firebasestorage.googleapis.com/v0/b/mesh-db-stg.appspot.com/o/icons%2Fmeshcomm-icon.png?alt=media&token=44dedc55-ef54-4256-a204-de8cd84829db" alt="Meshportal icon" /> -->
        <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">Meshportal Login</h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form class="space-y-6" @submit.prevent="login">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
              <div class="mt-2">
                <input id="email" name="email" type="email" autocomplete="email" v-model="email" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div class="mt-2">
                <input id="password" name="password" type="password" autocomplete="current-password" v-model="password" required="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="flex items-center justify-between">

              <div class="text-sm leading-6">
                <a id="forgotPassword" href="#" @click="forgotPassword" class="font-semibold text-indigo-400 hover:text-indigo-500">Forgot password?</a>
              </div>
            </div>

            <p id="error" :ref="error" v-if="error" class="font-semibold text-red-600 text-sm">{{ error }}</p>

            <div>
              <button id="login" type="submit" :disabled="isLoading" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ isLoading ? 'signing in...' : 'Sign in' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
//Import the necessary tools.
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '@/firebase';

export default {
  name: 'LoginMain',
  setup() {
    // Set up all the variable references.
    const email = ref('');
    const password = ref('');
    const isLoading = ref(false);
    const error = ref('');

    // Grab stored values
    const store = useStore();
    const router = useRouter();
    /**
     * Initiates when the login button is clicked. It sends an authentiaction
     * querry to firebase and if sucessfull, grabs user info from the database
     * and saves it.
     */
    const login = async () => {

      // Deactivate the login button and have it display "Loging in..."
      isLoading.value = true;

      // Send an authentication request and navigate the user to the Home page
      // if successfull.
      try {
        await authenticate(email.value, password.value);
        router.push({ name: 'Dashboard' });

      // The authentication failed. Display the proper exception message.
      } catch (exception) {

        // Activate the login button and have it display "Login"
        isLoading.value = false;

        if (exception.code === "auth/invalid-credential") {
          error.value = "Email or Password is invalid";

        } else if (exception.code === "auth/too-many-requests") {
          error.value = "Too many attempts were made and the account has been disabled. Please try again later.";

        } else {
          error.value = exception.code || "An Unexpected error occurred";
          console.log(exception);
        }
      }
    };

    /**
     * Sends and authentication request to firebase. If successful, it grabs the users
     * credentials and stores it.
     * @param   {String}  email     The email address of the user.
     * @param   {String}  password  The password associated with the email.
     */
    const authenticate = async (email, password) => {

      // Send the authentication request and grab the users credentials.
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Using the users UID, grab the document containing the users information.
      const userRef = doc(db, "users", userCredential.user.uid);
      const users = await getDoc(userRef);

      // Store the users information.
      if (users.exists()) {
        let userData = users.data();
        userData.entityData = {};
        userData.uid = users.id;

        const entityRef = doc(db, "entities", userData.entity);
        const entity = await getDoc(entityRef);
        if (entity.exists()) {
          userData.entityData[entity.id] = entity.data();
          userData.entityViewing = [entity.id]
        }

        if (userData.role === "admin" && userData.entityData[userData.entity].subEntities.length > 0) {
          userData.entityViewing = [entity.id];
          for (let subEntity of userData.entityData[userData.entity].subEntities) {
            const entityRef = doc(db, "entities", subEntity);
            const entity = await getDoc(entityRef);
            if (entity.exists()) {
              userData.entityData[entity.id] = entity.data();
            }
          }
        }
        store.dispatch('setUser', userData);
      } else {
        console.log("users data does not exist");
      }
    };

    /**
     * Initiates when the "Forgot Password" link is clicked. It sends the user
     * to the "/reset-password" page.
     */
    const forgotPassword = () => {
      router.push({ name: 'ResetPassword' });
    };

    return {
      email,
      password,
      isLoading,
      error,
      login,
      forgotPassword,
    };
  },
};
</script>

<style>
.login-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  background-image: url('@/assets/images/MeshComm-Hero-Area-SIGNAL.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
