import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/event';

class Event {

    static async get(path='', token=null) {
        // Regular GET request
        const response = await fetch(`${URL}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
        });
        return response.json();
    }

    static async post(path='', body, token=null) {
        // Regular POST request
        const response = await fetch(`${URL}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        });
        return response.json();
    }

    static async put(path='', body, token=null) {
        // Regular PUT request
        const response = await fetch(`${URL}${path}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        });
        return response.json();
    }

    static async delete(path='', body=null, token=null) {
        // Regular DELETE request
        const response = await fetch(`${URL}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        });
        return response.json();
    }
}

export default Event;