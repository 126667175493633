import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/calculation';

class Calculation {

    static async post(path='', body, token=null) {
        if (path === '') {
            // Regular POST request
            const response = await fetch(`${URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });
            return response.json();
        }
    }

    static async put(path='', body, token=null) {
        if (path === '') {
            const response = await fetch(`${URL}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });
            return response.json();
        }
    }
}

export default Calculation;