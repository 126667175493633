import { auth } from '@/firebase';

const URL = 'https://us-central1-mesh-db-stg.cloudfunctions.net/template_test';

class Template {

    static async put(path='', body, token=null) {
        const response = await fetch(`${URL}${path}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token || await auth.currentUser.getIdToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        return response.json();
    }
}

export default Template;