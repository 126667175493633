import { createRouter, createWebHistory } from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase.js'

// Main Pages
import HomeMain from '@/pages/Home.vue';
import LoginMain from '@/pages/Login.vue';
import ResetPassword from '@/pages/ResetPassword.vue';

// Device Pages
import MainDashboard from '@/pages/devices/Dashboard.vue';
import UnitDashboard from '@/pages/devices/UnitDashboard.vue';
import UnitDashboardTimeseries from '@/pages/devices/UnitDashboardTimeseries.vue';
import UnitDashboardEvents from '@/pages/devices/UnitDashboardEvents.vue';
import UnitDashboardManagement from '@/pages/devices/UnitDashboardManagement.vue';

// Sim Cards Pages
import SimDashboard from '@/pages/simCards/SimDashboard.vue';

// Settings Pages
import PortalSettings from '@/pages/settings/Settings.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: LoginMain
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/',
      name: 'Home',
      component: HomeMain,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: MainDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: PortalSettings,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID',
      name: 'UnitDashboad',
      component: UnitDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID/time-series',
      name: 'UnitDashboadTimeseries',
      component: UnitDashboardTimeseries,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID/events',
      name: 'UnitDashboadEvents',
      component: UnitDashboardEvents,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID/management',
      name: 'UnitDashboadManagement',
      component: UnitDashboardManagement,
      meta: { requiresAuth: true }
    },
    {
      path: '/sim-cards',
      name: 'SimCards',
      component: SimDashboard,
      meta: { requiresAuth: true }
    },
    // other routes...
  ]
});

// Create a promise-based auth check instead
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, 
      (user) => {
        unsubscribe(); // Unsubscribe immediately after getting the user state
        resolve(user);
      },
      (error) => {
        unsubscribe(); // Unsubscribe on error too
        reject(error);
      }
    );
  });
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  try {
    const user = await getCurrentUser();
    if (requiresAuth && !user) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } catch (error) {
    console.error("Auth check failed", error);
    next({ name: 'Login' });
  }
});

export default router;