<!-- eslint-disable no-undef -->
<script setup>
  import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

  const emit = defineEmits(['submitForm', 'close']);

  const props = defineProps({
    friendlyName: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
  })

  // Reactive references
  const friendlyName = ref(props.friendlyName);
  const description = ref(props.description);
  const image = ref(null);
  const loading = ref(false);

  // Lifecycle hooks
  onMounted(() => {
    document.addEventListener('keydown', handleKeydown);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', handleKeydown);
  });

  // Methouds
  const handleSubmit = () => {
    loading.value = true;
    const formData = {friendlyName: friendlyName.value, description: description.value, image: image.value};
    emit('submitForm', formData);
  };

  const setFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      image.value = file;
    }
  };

  const close = () => {
    emit('close');
  };

  // Handle ESC key
  const handleKeydown = (event) => {
    if (event.key === 'Escape') {
      close();
    }
  };
</script>

<template>
    <div 
      class="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center z-50" 
      @click.self="close"
      role="dialog"
      aria-labelledby="modal-title"
    >
      <div class=" bg-color-f p-[20px] rounded-lg max-w-[400px] max-h-[calc(100vh-50px)] overflow-y-auto">
        <h2 id="modal-title" class=" font-extrabold text-xl underline">Update Device</h2>
        <br>
        <form @submit.prevent="handleSubmit">
          <label>
            Friendly Name:
            <br>
            <input v-model="friendlyName" type="text" />
          </label>
          <br><br>
          <label>
            Description:
            <br>
            <input v-model="description" type="text" />
          </label>
          <br><br>
          <label>
            Image:
            <br>
            <input type="file" accept="image/*" @change="setFile($event)" />
          </label>
          <br><br>
          <div class="flex justify-between">
            <button @click="close" class="default-button">Close</button>
            <button type="submit" :disabled="loading" class="default-button">Update</button>
            <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          </div>
        </form>
      </div>
    </div>
</template>
  
<style scoped>
  input {
    width: 100%;
  }
  </style>