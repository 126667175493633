<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';
import unitImage from '@/assets/images/unit.png';

// Define props
const props = defineProps({
    unitData: Object,
    dataID: String,
    timeFormat: {
        type: String,
        default: '24hour'
    }
});

const friendlyName = ref(null);
const image = ref(unitImage);
const lastActivity = ref(null);
const borderColor = ref('black');

onMounted(() => {
    friendlyName.value = props.unitData.friendlyName;
    if (props.unitData.image) {
        const baseUrl = props.unitData.image;
        image.value = baseUrl;
    }
    lastActivity.value = timeSimplified(utcToLocal(props.unitData.lastActivityUTC, props.timeFormat)) || "no activity";

    if (lastActivity.value === 'Current') {
        borderColor.value = 'green';
    }
    else if (lastActivity.value === 'no activity') {
        borderColor.value = 'gray';
    }
    else {
        borderColor.value = 'red';
    }
});
</script>

<template>
    <div class="flex items-center bg-color-f dark:bg-color-f-darkmode rounded-lg p-[10px] m-[10px] text-left w-[96%] bg-color cursor-pointer">
        <div class="ml-[10px] content-center w-full">
            <h2 v-if="friendlyName" class="underline text-xl font-bold dark:text-dark-text">{{ friendlyName }}</h2>
            <p class="dark:text-dark-text"><span class="font-bold">Data ID: </span>{{ dataID }}</p>
            <p class="dark:text-dark-text"><span style="font-weight: bold;">Last Acvive: </span>{{ lastActivity }}</p>
        </div>
        <div class="p-1 rounded-full border-4 aspect-square w-[100px] h-[100px] flex items-center justify-center" :style="{ 'border-color': borderColor }">
            <img class="w-full h-full" 
                 :class="{
                     'rounded-full': image !== unitImage,
                     'object-contain p-2': image === unitImage
                 }"
                 :style="{
                     'transform': image !== unitImage ? 'scale(1.1)' : 'none'
                 }"
                 :src="image" 
                 alt="unit">
        </div>
    </div>
</template>