<!-- eslint-disable no-unused-vars -->
<script setup>
    // Imports
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { utcToLocal } from '@/tools/TimeFunctions';
    import Data from '@/api/data';
    import Device from '@/api/device';
    import DefaultLayout from '@/layouts/Layout.vue';
    import UpdateDevice from '@/components/modals/updateDevice.vue';
    import ActionsRequest from '@/components/forms/ActionsRequest.vue';
    import GenericTable2 from '@/components/tables/GenericTable2.vue';

    // Reactive references
    const store = useStore();
    const image = ref('');
    const friendlyName = ref('');
    const description = ref('');
    const unitDetails = ref({});
    const modal = ref(null);
    const editOption = ref(false);
    const layoutKey = ref(0);
    const requestStream = ref(null);
    const resultStream = ref(null);
    const actionHistory = ref([]);
    
    const route = useRoute();
    const dataID = route.params.dataID;
    const timeFormat = store.state.user.timeFormat;
    const user = store.state.user;

    // Lifecycle hook
    onMounted(async () => {
        handleDeviceDetails();
        streamActionHistory();
    });

    onUnmounted(() => {
        if (requestStream.value) {
            requestStream.value.cancel();
        }

        if (resultStream.value) {
            resultStream.value.cancel();
        }
    });

    // Methods
    const streamActionHistory = async () => {
        try {
            // Set up request Stream
            const requestBody = {
                dataID: dataID,
                subject: 'action_request'
            };
            
            requestStream.value = await Data.post('/stream', requestBody);
            
            // Handle the stream data
            requestStream.value.read(async (response) => {
                
                // Check if the response contains the actual data array
                if (response.body.status === 'success') {
                    // If the response is a heartbeat, just log it
                    if (response.body.details === 'heartbeat') {
                        console.log('Heartbeat received');
                        return;
                    }
                    
                    // Process the data
                    if (response.body.details.length > 0) {
                        for (const request of response.body.details) {
                            matchRequestsandResults(request, true);
                        }
                    } else {
                        console.log('No request data received');
                    }
                } else if (response.body && response.body.status === 'complete') {
                    console.log('Request stream complete');
                }
            });

            // Set up response Stream
            const resultBody = {
                dataID: dataID,
                subject: 'action_result'
            };
            
            resultStream.value = await Data.post('/stream', resultBody);
            
            // Handle the stream data
            resultStream.value.read(async (response) => {
                
                // Check if the response contains the actual data array
                if (response.body.status === 'success') {
                    // If the response is a heartbeat, just log it
                    if (response.body.details === 'heartbeat') {
                        console.log('Heartbeat received');
                        return;
                    }
                    
                    // Process the data
                    if (response.body.details.length > 0) {
                        for (const result of response.body.details) {
                            matchRequestsandResults(result, false);
                        }
                    } else {
                        console.log('No result data received');
                    }
                } else if (response.body && response.body.status === 'complete') {
                    console.log('Result stream complete');
                }
            });
        } catch (error) {
            console.error('Failed to start action history stream:', error);
        }
    };

    const matchRequestsandResults = (data, isRequest) => {
        if (data.id == 0) {
            return;
        }

        // Find existing entry by id
        const existingEntry = actionHistory.value.find(entry => entry["ID #"] === data.id);
        
        if (!existingEntry) {
            // Create new entry
            const newEntry = {
                "ID #": data.id,
                Setting: isRequest ? data.action_class : null,
                Request: isRequest ? data.action_type : null,
                "Time Sent": isRequest ? utcToLocal(data.messageUTC, timeFormat) : null,
                "Time Completed": !isRequest && data.status == "OK" ? utcToLocal(data.messageUTC, timeFormat) : null,
                Status: !isRequest ? data.status : "PENDING",
                Payload: !isRequest ? data.payload : null
            };
            
            // Find correct insertion point to maintain sorting
            const insertIndex = actionHistory.value.findIndex(entry => entry["ID #"] < data.id);
            if (insertIndex === -1) {
                actionHistory.value.push(newEntry);
            } else {
                actionHistory.value.splice(insertIndex, 0, newEntry);
            }
        } else {
            // Update existing entry
            if (isRequest) {
                existingEntry.Setting = data.action_class;
                existingEntry.Request = data.action_type;
                existingEntry["Time Sent"] = utcToLocal(data.messageUTC, timeFormat);
            } else {
                existingEntry["Time Completed"] = data.status == "OK" ? utcToLocal(data.messageUTC, timeFormat) : null;
                existingEntry.Status = data.status;
                existingEntry.Payload = data.payload;
            }
        }
    };

    
    const handleDeviceDetails = () => {
        try {
            const { 
                friendlyName: extractedName, 
                description: extractedDesc, 
                image: extractedImage,
                lastMessage,
                currentLon,
                currentLat,
                active,
                entities,
                size,
                lastActivityUTC,
                customTemplate,
                defaultTemplate,
                dataID,
                servicePlan,
                ...rest } = store.state.device;

            friendlyName.value = extractedName;
            description.value = extractedDesc;
            image.value = extractedImage;
            unitDetails.value = rest;
        } catch(error) {
            console.log(error)
        }
    };

    const updateDevice = async ($event) => {
        // Create the form data
        const formData = new FormData();
        formData.append('dataID', dataID);
        if ($event.friendlyName) formData.append('friendlyName', $event.friendlyName);
        if ($event.description) formData.append('description', $event.description);
        if ($event.image) formData.append('image', $event.image);

        // Update the device
        try {
            const response = await Device.put('', formData);

            if (response.status !== 'success') {
                console.log(response);
                alert('Failed to update device');
                modal.value = null;
                return;
            }

            // Update the device details and store them.
            const updatedDevice = store.state.device;
            updatedDevice.image = response.details.image;
            store.dispatch('setDevice', updatedDevice);
            handleDeviceDetails();
            
            modal.value = null;
            layoutKey.value++;
        } catch (error) {
            modal.value = null;
            await new Promise(resolve => setTimeout(resolve, 300));
            alert('Failed to update device');
        }
    };
</script>

<template>
    <DefaultLayout
    :key="layoutKey"
    :dataID="dataID"
    >
        <div id="Details" class="flex flex-col md:flex-row justify-between gap-8 mr-3">

            <!-- Device Details -->
            <div id="DeviceDetails" class="flex-[1]">
                <br>
                <div id="friendlyDetails" class="flex flex-row p-2 cursor-pointer" @mouseover="editOption = true" @mouseleave="editOption = false" @click="modal = 'updateDevice'">
                    <div v-if="image" class="w-60 h-60">
                        <img :src="image" alt="Device Image" class="w-full h-full">
                    </div>
                    <div v-else class="w-60 h-60 border-2 border-solid border-black flex items-center justify-center">
                        <span class="text-black">No Image</span>
                    </div>
                    <div class="flex-1 ml-5">
                        <h1 class="text-4xl font-bold mt-10">{{ friendlyName || dataID }}</h1>
                        <p class="text-xl mt-10">{{ description}}</p>
                    </div>
                    <font-awesome-icon
                        v-if="editOption"
                        :icon="['fas', 'edit']" 
                        class="h-5 w-5 mt-2"
                    />
                </div>
                <ul class="list-disc ml-5 mt-10">
                    <li v-for="(value, key) in unitDetails" :key="key" class="font-bold">
                        {{ key }}: <span class="font-normal">{{ value }}</span>
                    </li>
                </ul>
            </div>

            <!-- Action Request -->
            <div v-if="user.role === 'admin'" id="actionRequest" class="flex-[1]">
                <br>
                <ActionsRequest
                    :dataID="dataID"
                ></ActionsRequest>
            </div>
        </div>
        <br>

        <!-- Action Request History -->
        <div v-if="user.role === 'admin'" class="bg-white mr-3 mt-[50px] rounded-lg">
            <div class="flex flex-row justify-between">
                <h1 class="text-2xl font-bold underline ml-3 mt-3">Action Request History</h1>
            </div>
            <br>
            <GenericTable2
                :data="actionHistory"
                :pagination="true"
            />
        </div>
    </DefaultLayout>
    <UpdateDevice v-if="modal === 'updateDevice'"
        :friendlyName="friendlyName"
        :description="description"
        @close="modal = null"
        @submitForm="updateDevice"
    ></UpdateDevice>
</template>
